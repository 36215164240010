/* eslint-disable react-refresh/only-export-components */
import classNames from 'classnames';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, ReactNode, useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { useHandleTouch } from '~/hooks/useHandleTouch.hook';

import LoaderIcon from '../Loader/LoaderIcon';

export const buttonTypes = [
  'primary',
  'secondary',
  'delete',
  'delete-dark',
  'select-active',
  'select-inactive',
  'selection',
] as const;
type ButtonType = (typeof buttonTypes)[number];

export const buttonSizes = ['tiny', 'small', 'medium', 'large'] as const;
export type ButtonSizeType = (typeof buttonSizes)[number];

export interface ButtonBaseProps {
  variant?: ButtonType;
  size?: ButtonSizeType;
  disabled?: boolean;
  /** Only texts/labels are allowed to prevent anti-patterns of nesting things inside a `<Button />`. */
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
  loadingPercentage?: number;
  onClick?: () => void;
}

export type ButtonProps = (
  | (ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' })
  | (AnchorHTMLAttributes<HTMLAnchorElement> & Omit<NavLinkProps, 'to'> & { as: 'a' })
) &
  ButtonBaseProps;

const getTextSize = (size: ButtonProps['size']) => {
  switch (size) {
    case 'tiny': {
      return 'text-lg';
    }
    case 'small': {
      return 'text-xl';
    }
    case 'large': {
      return 'text-4xl';
    }
    case 'medium':
    default: {
      return 'text-2xl';
    }
  }
};

const getSizeClasses = (size: ButtonProps['size']) => {
  switch (size) {
    case 'tiny': {
      return 'px-2 text-xs text-base leading-normal h-6';
    }
    case 'small': {
      return 'p-4 text-base leading-normal h-14';
    }
    case 'large': {
      return 'px-7 py-5 text-2xl leading-[2.25rem]';
    }
    case 'medium':
    default: {
      return 'px-4 py-5 text-lg leading-7 h-16';
    }
  }
};

const getVariantClasses = (variant: ButtonProps['variant']) => {
  switch (variant) {
    case 'secondary':
      return 'text-neutral-900 border-neutral-200 hover:bg-neutral-100 focus:bg-neutral-200 rounded-lg';
    case 'delete':
      return 'text-neutral-900 border-neutral-200 bg-white hover:bg-neutral-100 focus:bg-neutral-200 rounded-lg';
    case 'delete-dark':
      return 'text-white bg-black border-neutral-500 hover:bg-neutral-800 focus:bg-neutral-700 rounded-lg';
    case 'select-inactive':
      return 'text-white bg-neutral-800 border-neutral-800 hover:bg-neutral-800 focus:bg-neutral-700 rounded-lg';
    case 'select-active':
      return 'text-neutral-800 bg-white border-neutral-200 hover:bg-neutral-200 focus:bg-white rounded-lg';
    case 'selection':
      return 'hover:border-primary border-neutral-300 rounded-full focus:border-primary';
    case 'primary':
    default:
      return 'text-contrast bg-primary border-primary hover:bg-primary-500 hover:border-primary-500 focus:bg-primary-700 focus:border-primary-700 rounded-lg';
  }
};

const getDisabledClasses = (disabled: boolean) => {
  return disabled
    ? 'disabled:text-neutral-600 disabled:pointer-events-none disabled:bg-neutral-400 disabled:border-neutral-400'
    : '';
};

const BASE_BUTTON_CLASSES =
  'cursor-pointer border-2 font-bold inline-flex outline-0 justify-center items-center gap-2 relative z-0';

const Button: FC<ButtonProps> = ({
  variant,
  size = 'medium',
  disabled = false,
  children,
  startIcon,
  endIcon,
  fullWidth,
  loading,
  loadingPercentage,
  className,
  ...props
}) => {
  const computedClasses = useMemo(() => {
    const variantClass = getVariantClasses(variant);
    const sizeClass = getSizeClasses(size);
    const disabledClass = getDisabledClasses(disabled || (loading ?? false));
    const widthClass = fullWidth ? 'w-full' : '';

    return [variantClass, sizeClass, disabledClass, widthClass].join(' ');
  }, [variant, size, disabled, loading, fullWidth]);

  const { handleMouseUp, handleMouseDown, handleTouchStart, handleTouchEnd } = useHandleTouch(props.onClick);

  const propsWithoutOnClick = Object.fromEntries(Object.entries(props).filter(([key]) => key !== 'onClick'));

  const iconClass = getTextSize(size);
  if (props.as === 'a' && props.href !== undefined) {
    return (
      <NavLink
        className={classNames(BASE_BUTTON_CLASSES, computedClasses, className)}
        to={props.href}
        {...(propsWithoutOnClick as AnchorHTMLAttributes<HTMLAnchorElement>)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleTouchEnd}
        onTouchStart={handleTouchStart}
      >
        {startIcon && !loading && <div className={iconClass}>{startIcon}</div>}
        {children}
        {endIcon && !loading && <div className={iconClass}>{endIcon}</div>}
      </NavLink>
    );
  }

  return (
    <button
      className={variant ? classNames(BASE_BUTTON_CLASSES, computedClasses, className) : className}
      disabled={disabled || loading}
      {...(propsWithoutOnClick as ButtonHTMLAttributes<HTMLButtonElement>)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          e.preventDefault();
          props.onClick?.();
        }
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
    >
      {loadingPercentage !== undefined && (
        <span
          className=" absolute left-0 top-0 -z-10 h-full rounded-l-lg bg-neutral-700 opacity-10 transition-all duration-1000 ease-linear"
          style={{ width: `${loadingPercentage}%` }}
        />
      )}
      {startIcon && <div className={iconClass}>{startIcon}</div>}
      {children}
      {endIcon && !loading && <div className={iconClass}>{endIcon}</div>}
      {loading && (
        <div className={iconClass}>
          <LoaderIcon color="white" />
        </div>
      )}
    </button>
  );
};

export default Button;
