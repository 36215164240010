import * as Sentry from '@sentry/react';
import { FC, PropsWithChildren, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { type Api } from '~/api-client';
import { env } from '~/env';
import routes from '~/routes';
import { merge } from '~/utils/merge.util';

import { useAppRouting } from '../useAppRouting.hook';
import { PersistenceSpecification, useBrowserPersistence } from '../useBrowserPersistence.hook';
import useMediaQuery from '../useMediaQuery.hook';
import { ApiHeaders, ApiKioskHeaders, ApiNonKioskHeaders, AppContextContext, RenderTarget } from './AppContext';
import { AppContext, appContextSchema, RuntimeMode, ScanAndGoMode } from './appContext.schema';
import { emptyAppContext } from './emptyAppContext.constant';

const updateSentryDetails = (appContext: AppContext) => {
  Sentry.setUser({
    id: appContext.client.clientId
      ? `${appContext.client.clientId}${appContext.device.selectedKiosk && appContext.device.selectedVirtualDevice ? `:${appContext.device.selectedKiosk.name} [${appContext.device.selectedVirtualDevice.name}]` : ''}`
      : undefined, // No `clientId` means no user
  });
  Sentry.setTag('isKiosk', appContext.device.isKiosk);
  Sentry.setTag('clientId', appContext.client.clientId ?? undefined);
  Sentry.setExtra('device', appContext.device);
};

/**
 * AppContextProvider, used to provide and set the app context
 */
export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { mode } = useParams();
  const { isAppOnRoute } = useAppRouting();
  const [renderTarget, setRenderTarget] = useState<RenderTarget>('kiosk');
  const largeBreakpoint = useMediaQuery('lg');

  const [appContext, setAppContext] = useBrowserPersistence<AppContext>(
    PersistenceSpecification.AppContext,
    emptyAppContext,
    appContextSchema,
  );

  /**
   * For development purposes only: tweak the AppContext to simulate different scenarios without
   * needing to make modifications in the Backoffice that could affect other users.
   * Do not commit your tweaks to git though.
   */
  const applyDevTweaks = useCallback((appContext: AppContext) => {
    if (!appContext) return;
    /* Just an example of what you could do here:
    appContext.client.availableScanAndGoMode = 'inAppPayment'; // force a specific scanAndGo mode
    appContext.device.prescribedScanAndGoMode = 'inAppPayment'; // force a specific scanAndGo mode
    appContext.device.printers = []; // simulate absence of printers
    */
  }, []);

  /**
   * Determine and set the `customer.runtimeMode` from three dependencies:
   * 1. `device.prescribedScanAndGoMode` (from backend, relevant to kiosk)
   * 2. `client.availableScanAndGoMode` (from backend, relevant to non-kiosk)
   * 3. The initial route (from frontend, relevant to non-kiosk)
   */
  const setCustomerRuntimeMode = useCallback(
    (appContext: AppContext) => {
      // Determine scanAndGoMode
      let scanAndGoMode: ScanAndGoMode | null = null;

      if (appContext.device.isKiosk) {
        // For kiosk, the scanAndGoMode is determined in backend (is set per VirtualTerminal)
        scanAndGoMode = appContext.device.prescribedScanAndGoMode;
      } else {
        // For non-kiosk, the scanAndGoMode is determined in frontend and depends on:
        // a) whether backend has allowed/denied scanAndGoMode:
        if (!appContext.client.availableScanAndGoMode) {
          scanAndGoMode = null;
        }
        // b) whether user started his/her session on 's' instead of 'm':
        else if (mode === 's') {
          scanAndGoMode = appContext.client.availableScanAndGoMode;
        }
        // c) for the scanAndGo qr starting route (has no 'mode' param)
        else if (isAppOnRoute(routes.qrScanAndGo)) {
          scanAndGoMode = appContext.client.availableScanAndGoMode;
        }
        // d) no closing `else`, meaning: once scanAndGoMode is determined, it stays that way during the shopping session
      }

      // Map the arrived at scanAndGoMode to a runtimeMode
      let runtimeMode: RuntimeMode;
      switch (scanAndGoMode) {
        case 'relayPayment':
          runtimeMode = 'scanAndGoRelayPayment';
          break;
        case 'inAppPayment':
          runtimeMode = 'scanAndGoInAppPayment';
          break;
        case null:
        default:
          runtimeMode = 'regular';
          break;
      }

      appContext.customer.runtimeMode = runtimeMode;
    },
    [isAppOnRoute, mode],
  );

  /** setAppContext wrapper to allow for side effects that need to happen when the AppContext changes. */
  const setAppContextWrapper = useCallback(
    (action: SetStateAction<AppContext>) => {
      setAppContext((curr) => {
        const newAppContext = action instanceof Function ? action(curr) : action;
        if (env.isDevelopment) applyDevTweaks(newAppContext);
        setCustomerRuntimeMode(newAppContext);
        updateSentryDetails(newAppContext);
        return newAppContext;
      });
    },
    [applyDevTweaks, setCustomerRuntimeMode, setAppContext],
  );

  // Resets only the order-specific customer properties
  const resetOrderForCustomer = useCallback(() => {
    setAppContextWrapper((curr) => {
      return merge(curr, {
        customer: {
          ...curr.customer,
          alternativeOrderIdentifier: null,
          alternativeOrderIdentifierType: null,
          orderType: null,
        },
      });
    });
  }, [setAppContextWrapper]);

  const setFromSession = useCallback(
    (data: Api.SessionResult) => {
      setAppContextWrapper((curr) => {
        const res = merge(curr, {
          customer: {
            mobileUserGuid: data.userGuid,
          },
        });
        return res;
      });
    },
    [setAppContextWrapper],
  );

  /** Map api model to domain model */
  const mapScanAndGoConfig = (
    apiScanAndGo: Api.ScanAndGo | null,
  ): { availableScanAndGoMode: ScanAndGoMode | null; prescribedScanAndGoMode: ScanAndGoMode | null } => {
    if (!apiScanAndGo) return { availableScanAndGoMode: null, prescribedScanAndGoMode: null };

    // Note: Api currently returns an array of options, both for property `mobile` and `device`. Once API has fixed this, replace the `includes` with an `===`
    return {
      availableScanAndGoMode: apiScanAndGo.mobile?.includes('inapp')
        ? 'inAppPayment'
        : apiScanAndGo.mobile?.includes('qrrelay')
          ? 'relayPayment'
          : null,
      // For kiosk:
      prescribedScanAndGoMode: apiScanAndGo.device?.includes('inapp')
        ? 'inAppPayment'
        : apiScanAndGo.device?.includes('qrrelay')
          ? 'relayPayment'
          : null,
    };
  };

  const setFromStartup = useCallback(
    (data: Api.StartupResult, overwriteContext: DeepPartial<AppContext> = {}) => {
      setAppContextWrapper((curr) => {
        const scanAndGoConfig = mapScanAndGoConfig(data.scanAndGo);

        const res = merge(
          curr,
          {
            // Overwrite with the startup data
            client: {
              refetch: false,
              clientId: data.clientId,
              clientGuid: data.clientGuid,
              clientName: data.clientName,
              clientSlug: data.slug ?? data.clientGuid,
              availableScanAndGoMode: scanAndGoConfig.availableScanAndGoMode,
              hasEatIn: data.hasEatIn,
              hasTakeOutRegular: data.hasTakeOut,
              hasTakeOutCustom: data.hasTakeOutForTheRoad,
              takeOutCustomName: data.nameFortheroad,
              takeOutCustomImage: data.imageFortheroad,
              hasBuyNowTakeLater: data.selforderingHasBuyNowTakeLater,
              hasHomeDelivery: data.hasHomeDelivery,
              customerIdentificationMethod: data.customerIdentificationMethod,
              idleScreenBg: data.idleScreenBg,
              lockScreenBg: data.lockScreenBg,
              // inactivity durations (seconds): API provided values <= 0 could cause erratic behavior, hence math.Max(1, ...)
              inactivityDurationForDialogAppearance: Math.max(1, data.timeoutToConfirmPopup),
              inactivityDurationForSessionReset: Math.max(1, data.timeoutConfirmToStartScreen),
              logo: {
                ...data.logo,
              },
              receiptLogo: {
                ...data.receiptLogo,
              },
              styles: {
                primaryColor: data.primaryColor,
              },
              defaultLanguage: data.culture ?? undefined,
              virtualDevices: data.virtualDevices ?? [],
              startMovie: data.startMovie,
              cultures: data.cultures,
              openingTimes: data.openingTimes,
              todaysTimeSlots: data.todaysTimeSlots,
              promoBanner: data.promoBanner,
            },
            device: {
              printers: (data.printers ?? [])
                // workaround: as the API does not provide a technical id (yet),
                // a pseudo value is derived from functional properties
                .map((p) => ({
                  ...p,
                  pseudoId: `${p.printMode}|${p.name}|${p.ipAddress}|${p.serialNumber}`,
                })),
              selectedKiosk: data.selectedKiosk,
              selectedVirtualDevice: data.selectedVirtualDevice,
              prescribedScanAndGoMode: scanAndGoConfig.prescribedScanAndGoMode,
            },
          },
          overwriteContext,
        );
        return res;
      });
    },
    [setAppContextWrapper],
  );

  useEffect(() => {
    if (appContext.device.isKiosk) {
      setRenderTarget('kiosk');
    } else {
      largeBreakpoint ? setRenderTarget('desktop') : setRenderTarget('mobile');
    }
  }, [appContext.device.isKiosk, largeBreakpoint]);

  const headers = useMemo<ApiHeaders | undefined>(() => {
    if (appContext.device.isKiosk) {
      if (!appContext.device.deviceId) return undefined;
      return {
        secApplicationId: env.VITE_SEC_APPLICATION_ID,
        deviceId: appContext.device.deviceId,
      } satisfies ApiKioskHeaders;
    }
    if (!appContext.client.clientGuid) return undefined;
    return {
      secApplicationId: env.VITE_SEC_APPLICATION_ID,
      clientGuid: appContext.client.clientGuid,
      userGuid: appContext.customer.mobileUserGuid,
    } satisfies ApiNonKioskHeaders;
  }, [
    appContext.client.clientGuid,
    appContext.customer.mobileUserGuid,
    appContext.device.deviceId,
    appContext.device.isKiosk,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.info(
      `%c${env.VITE_NAME}%c %c${env.VITE_APP_VERSION}%c %c${env.VITE_APP_ENV}%c %c${
        appContext.device.isKiosk ? 'kiosk' : 'non-kiosk'
      }`,
      'background: #F17F3D; border-radius:0.5em; padding:0.1em 0.5em; color: black; font-weight: bold',
      '',
      'background: #3DBAF2; border-radius:0.5em; padding:0.1em 0.5em; color: black;',
      '',
      'background: #3DF2B4; border-radius:0.5em; padding:0.1em 0.5em; color: black;',
      '',
      'background: #566A73; border-radius:0.5em; padding:0.1em 0.5em; color: white;',
    );
    if (env.isProduction) return;

    // eslint-disable-next-line no-console
    if (appContext.device.isKiosk) console.log('📱 To exit KIOSK mode, go to: http://localhost:3000/exit');
    // eslint-disable-next-line no-console
    else console.log('🖥️ To enter KIOSK mode, sign in at: http://localhost:3000/kiosk');
  }, [appContext.device.isKiosk]);

  useEffect(() => {
    updateSentryDetails(appContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Explicitly don't depend on `appContext` to only run this once

  return (
    <AppContextContext.Provider
      value={{
        appContext,
        setAppContext: setAppContextWrapper,
        setFromStartup,
        setFromSession,
        headers,
        renderTarget,
        resetOrderForCustomer,
      }}
    >
      {children}
    </AppContextContext.Provider>
  );
};

AppContextProvider.displayName = 'AppContextProvider';
