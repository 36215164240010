import { useCallback } from 'react';

import useAndroidHost from './useAndroidHost';
import { PersistenceSpecification, useBrowserPersistence } from './useBrowserPersistence.hook';
import { useNetworkState } from './useNetworkState.hook';

interface CacheManagerState {
  timestamp: number;
}

/**
 * Cache operations
 */
export function useCacheManager() {
  const { isAPKDevice, clearApkCache } = useAndroidHost();
  const networkState = useNetworkState();

  /**
   * Keep track of latest CacheManager run. Consult TSO-429.
   */
  const [cacheManagerState, setCacheManagerState] = useBrowserPersistence<CacheManagerState>(
    PersistenceSpecification.CacheManager,
    { timestamp: Date.now() },
    undefined,
  );

  /**
   * Purge serviceworker caches and APK Webview cache
   */
  const clearAllCaches = useCallback(async () => {
    // if we are offline, we never clear the cache, since we will probably not be able to refetch the assets
    // no need to give the user feedback on this, since the user will already be on the offline page
    if (networkState === 'offline') return;
    // A) clear serviceworker cache (only available on httpS);
    if (window.location.protocol === 'https:') {
      // delete all caches declared in vite.config.ts
      const cacheKeys = await window.caches.keys();
      await Promise.all(
        cacheKeys.map(async (k) => {
          // eslint-disable-next-line security-node/detect-crlf, no-console
          console.log(`clearing window.caches('${k}')...`);
          await window.caches.delete(k);
        }),
      );
    }

    // B) clear browser cache of the APK webview (synchronous call)
    if (isAPKDevice) clearApkCache();

    setCacheManagerState({ timestamp: Date.now() });
  }, [clearApkCache, isAPKDevice, networkState, setCacheManagerState]);

  return { clearAllCaches, stateTimestamp: cacheManagerState.timestamp };
}
