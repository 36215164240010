import { Inventory } from './Inventory.typings';

export const emptyInventory: Inventory = {
  allergies: [],
  discountEvents: [],
  menus: [],
  preparationChoicesById: {},
  productsById: {},
  productTags: [],
  promoProducts: [],
  promoProductsName: undefined,
  hasProductsWithStock: false,
  sections: [],
  sectionsById: {},
  allowedSearchableProducts: [],
};
