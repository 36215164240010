// Base config that CANNOT include `env` imports as this file is also used in the `vite.config.ts` file.

export const languages = {
  'nl-NL': { nativeName: 'Nederlands', culture: 'nl' },
  'en-US': { nativeName: 'English', culture: 'en-US' },
  'de-DE': { nativeName: 'Deutsch', culture: 'de-DE' },
  'it-IT': { nativeName: 'Italiano', culture: 'it' },
  'es-ES': { nativeName: 'Español', culture: 'es' },
  'fr-FR': { nativeName: 'Français', culture: 'fr' },
};
export type Locale = keyof typeof languages;

export const fallbackLng: Locale = 'nl-NL';
