/** @type {import('tailwindcss').Config} */

import defaultTheme from 'tailwindcss/defaultTheme';
import plugin from 'tailwindcss/plugin';

export default {
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    extend: {
      transitionProperty: {
        height: 'height',
      },
      fontFamily: {
        Inter: ['Inter Variable', 'sans-serif'],
      },
      colors: {
        primary: 'var(--bg-brand)',
        'primary-500': 'var(--bg-brand-500)',
        'primary-700': 'var(--bg-brand-700)',
        'primary-light': 'var(--bg-brand-light)',
        contrast: 'var(--text-color)',
        red: {
          '50': '#FFE2EA',
          '100': '#FFC4CF',
          '200': '#F48F96',
          '300': '#EA6772',
          '400': '#F64250',
          '500': '#FA2837',
          '600': '#EA1C36',
          '700': '#D7002F',
          '800': '#CA0028',
          '900': '#BC001B',
        },
        green: {
          '50': '#CBFFC9',
          '100': '#AFFFA8',
          '200': '#8AEC81',
          '300': '#23DC50',
          '400': '#41CF38',
          '500': '#00C200',
          '600': '#00B300',
          '700': '#009F00',
          '800': '#008E00',
          '900': '#007000',
        },
        blue: {
          '50': '#C4BFCB',
          '100': '#AB9EC0',
          '200': '#8D77B0',
          '300': '#6F51A3',
          '400': '#583397',
          '500': '#41148D',
          '600': '#371189',
          '700': '#2A057E',
          '800': '#190078',
          '900': '#00006A',
        },
        neutral: {
          '50': '#FAFAFA',
          '100': '#F5F5F5',
          '200': '#E5E5E5',
          '300': '#D4D4D4',
          '400': '#A3A3A3',
          '500': '#737373',
          '600': '#525252',
          '700': '#404040',
          '800': '#262626',
          '900': '#171717',
        },
        info: {
          '50': '#C4BFCB',
          '100': '#AB9EC0',
          '200': '#8D77B0',
          '300': '#6F51A3',
          '400': '#583397',
          '500': '#41148D',
          '600': '#371189',
          '700': '#2A057E',
          '800': '#190078',
          '900': '#00006A',
        },
        success: {
          '50': '#CBFFC9',
          '100': '#AFFFA8',
          '200': '#8AEC81',
          '300': '#23DC50',
          '400': '#41CF38',
          '500': '#00C200',
          '600': '#00B300',
          '700': '#009F00',
          '800': '#008E00',
          '900': '#007000',
        },
        warning: {
          '50': '#FFFFC1',
          '100': '#FFF2A9',
          '200': '#FFCC87',
          '300': '#FFA860',
          '400': '#FF8E44',
          '500': '#FD7021',
          '600': '#F1641F',
          '700': '#DE5412',
          '800': '#D14117',
          '900': '#C02B11',
        },
        danger: {
          '50': '#FFE2EA',
          '100': '#FFC4CF',
          '200': '#F48F96',
          '300': '#EA6772',
          '400': '#F64250',
          '500': '#FA2837',
          '600': '#EA1C36',
          '700': '#D7002F',
          '800': '#CA0028',
          '900': '#BC001B',
        },
        white: '#FFFFFF',
        black: '#000000',
      },
      borderRadius: {
        none: '0',
        DEFAULT: '4px',
        sm: '2px',
        md: '6px',
        lg: '8px',
        xl: '12px',
        '2xl': '16px',
        '3xl': '24px',
        full: '9999px',
      },
      boxShadow: {
        sm: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
        DEFAULT: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
        md: '0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.10)',
        lg: '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -4px rgba(0, 0, 0, 0.10)',
        xl: '0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 8px 10px -6px rgba(0, 0, 0, 0.10)',
        '2xl': '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
        inner: '0px 2px 4px 0px rgba(0, 0, 0, 0.05) inset',
      },
      blur: {
        none: '0',
        sm: '2px',
        DEFAULT: '4px',
        md: '6px',
        lg: '8px',
        xl: '12px',
        '2xl': '20px',
        '3xl': '32px',
      },
    },
    screens: {
      ...defaultTheme.screens,
    },
  },
  plugins: [
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.scrollbar-hide': {
          /* IE and Edge */
          '-ms-overflow-style': 'none',

          /* Firefox */
          'scrollbar-width': 'none',

          /* Safari and Chrome */
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
        '.fadeIn': {
          animation: 'fadeIn 0.5s ease normal',
        },
      });
    }),
  ],
};
