import { ZodiosHooks } from '@zodios/react';
import axios from 'axios';

import { env } from '~/env';

import { createApiClient } from './generated/swagger';
import { sentryPlugin } from './sentryPlugin';

export * from './api.d';

const axiosInstance = axios.create();
export const apiClient = createApiClient(env.VITE_API_URL, { axiosInstance });
apiClient.use(sentryPlugin);

export const apiHooks = new ZodiosHooks('selfOrderApi', apiClient);
