import { createContext, Dispatch, SetStateAction } from 'react';

import { Api } from '~/api-client';
import { ProductDetailsProps } from '~/components/UI/Molecules/ProductDetails/ProductDetails';

import { ApiHeaders } from '../useAppContext/AppContext';
import { Inventory, type Product } from '../useInventory';
import { Cart, CartProductGrouping } from './cart.schema';
import { emptyCart } from './emptyCart.constant';

export type DialogOptions = {
  productGrouping: CartProductGrouping | CartProductGrouping[];
  incrementOrDecrement?: 'increment' | 'decrement';
  showMenuForm?: boolean;
};

export type DialogConfirmOptions = {
  onConfirm: () => void;
};

export type DialogAgeCheckOptions = {
  // Passed from Cart page to trigger that specific `isLoading` state.
  submitOrder: () => void;
};

export type DialogCustomizableProductOptions = {
  product: Product;
  showMenuForm: boolean;
  shouldClearTempCart: boolean;
  lineItemToEdit?: CartProductGrouping;
};

export type Dialog = {
  'half-time'?: true;
  'make-menu'?: DialogOptions;
  'line-id'?: DialogOptions;
  'age-check'?: DialogAgeCheckOptions;
  'product-detail'?: ProductDetailsProps;
  'confirm-delete'?: DialogOptions;
  'confirm-restart'?: DialogConfirmOptions;
  'unavailable-products'?: DialogOptions;
  'customizable-product'?: DialogCustomizableProductOptions;
};

export type DialogType = keyof Dialog;

export interface CartProductGroupingInputSimple {
  /** Simple product. */
  simpleProduct: Product;
}
export interface CartProductGroupingInputTempCart {
  productGrouping: CartProductGrouping;
}
export type CartProductGroupingInput = CartProductGroupingInputSimple | CartProductGroupingInputTempCart;

export type ShowDialogFunction = <T extends DialogType>(type: T, options?: Dialog[T]) => void;

export type OrderStateType = 'submitted' | 'confirmed' | 'completed' | 'aborted';

export type FinalizedOrder = (Api.FinalizedOrderSet & { orderState: OrderStateType }) | undefined;

export interface CartValue {
  /** Cart data. */
  cart: Cart;
  /** Get the number of line-items in the cart for a `Product`. */
  countByProductId: (productId: number) => number;
  /** Spanning all `cart.orders`, get the total number of a `Product` in the cart, regardless whether it is put in the cart as is, or as part of a menu. */
  totalCountByProductId: (productId: number) => number;
  /** A helper to quickly find the line-items for a `Product` by `product.id`. */
  lineItemsByProductId: Map<number, CartProductGrouping[]>;
  /** Clear/ empty/ reset entire cart. */
  clearCart: () => void;
  /** Add a `productGrouping` to the cart, possible to skip upgrade option when adding a menu to cart. */
  addToCart: (
    productGrouping: CartProductGroupingInput,
    menuCheck?: boolean,
    shouldClearDialog?: boolean,
    cb?: () => void,
  ) => void;
  /** Add delivery address and timeslot to cart */
  addDeliveryToCart: (deliveryInfo: Api.DeliveryInfo) => void;
  /** Increment the count on a line-item by 1. */
  incrementLineItem: (cartProductGrouping?: CartProductGrouping, product?: Product) => void;
  /** Decrement the count on a line-item by 1. */
  decrementLineItem: (cartProductGrouping?: CartProductGrouping, product?: Product, shouldConfirm?: boolean) => void;
  /** Dialog, will be set from context of cart. */
  dialog?: Dialog;
  /** Show dialog function to show dialog. */
  showDialog: ShowDialogFunction;
  /** Indicates whether an open dialog is temporarily suppressed. */
  isDialogSuppressed: boolean;
  /** Turn on/off the temporary suppression of the open dialog. */
  suppressDialog: (turnOn: boolean) => void;
  /** Clear function for dialog. */
  clearDialog: () => void;
  /** Toggle order type. */
  toggleOrderType: (type?: Api.TimingOption) => void;
  /** Whether the user has accepted to verify his age to buy a product with a minimum age. */
  hasAcceptedMinimumAgeCheck: boolean;
  /** Set whether the user has accepted to verify his age to buy a product with a minimum age. */
  setHasAcceptedMinimumAgeCheck: (hasAcceptedMinimumAgeCheck: boolean) => void;
  /** Finalized order. */
  finalizedOrder?: FinalizedOrder;
  /** Set finalized order. */
  setFinalizedOrder: Dispatch<SetStateAction<FinalizedOrder>>;
  /** Get product id for a line item. */
  getProductIdForLineItem: (lineItem: CartProductGrouping) => number;
  /** Validate function to check for products in inventory. */
  validateCart: (inventory: Inventory) => void;
  /** Remove a line-item from the cart. */
  removeLineItem: (lineId: string, outOfStock?: boolean) => void;
  /** Minimum age required to order specific products */
  minimumAge: number;
  /** stash the cart serverside, for scanAndGo relaypayment */
  stashCart: (headers: ApiHeaders) => Promise<string>;
  /** import the serverside stashed cart, for scanAndGo relaypayment */
  importStashedCart: (cartStashGuid: string, headers: ApiHeaders) => Promise<Cart | undefined>;
  /** Check if a product is in the cart */
  isProductIdInCart: (productId: number) => boolean;
}

function createEmptyFn<T>(fnName: string, r?: T): () => T {
  return () => {
    // eslint-disable-next-line no-console
    console.warn(`Cart.${fnName} called before initialized`);
    return r as T;
  };
}

export const CartContext = createContext<CartValue>({
  cart: emptyCart,
  countByProductId: createEmptyFn('countByProductId', NaN),
  totalCountByProductId: createEmptyFn('totalCountByProductId', NaN),
  clearCart: createEmptyFn('clear'),
  addToCart: createEmptyFn('add'),
  addDeliveryToCart: createEmptyFn('addDelivery'),
  incrementLineItem: createEmptyFn('increment'),
  decrementLineItem: createEmptyFn('decrement'),
  lineItemsByProductId: new Map(),
  dialog: undefined,
  showDialog: createEmptyFn('showDialog'),
  suppressDialog: createEmptyFn('showDialog'),
  isDialogSuppressed: false,
  clearDialog: createEmptyFn('clearDialog'),
  hasAcceptedMinimumAgeCheck: false,
  setHasAcceptedMinimumAgeCheck: createEmptyFn('setHasAcceptedMinimumAgeCheck'),
  toggleOrderType: createEmptyFn('toggleOrderType'),
  finalizedOrder: undefined,
  setFinalizedOrder: createEmptyFn('setFinalizedOrder'),
  getProductIdForLineItem: createEmptyFn('getProductIdForLineItem'),
  validateCart: createEmptyFn('validateCart'),
  removeLineItem: createEmptyFn('removeOutOfStock'),
  minimumAge: 18,
  stashCart: createEmptyFn('stashCart'),
  importStashedCart: createEmptyFn('importStashedCart'),
  isProductIdInCart: createEmptyFn('isProductIdInCart', false),
});

CartContext.displayName = 'CartContext';
