import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { env } from '~/env';
import routes from '~/routes';

import { AppContext } from './useAppContext/appContext.schema';
import { useCacheManager } from './useCacheManager.hook';

const DEFAULT_POLL_INTERVAL = 10; // minutes

/**
 * Handles everything related to getting a client machine up to date with a new release
 * Steps, in chronological order:
 * 1) poll for new versions
 * 2) assess whether a deployment is to be triggered.
 *   If so:
 * 3) deploy a new version
 * 4) apply migrations in case of any breaking changes that would otherwise cause erratic behaviors
 */
export function useDeploymentAgent() {
  const cacheManager = useCacheManager();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  /**
   * Increment when adding a new migration
   */
  const migrationSequenceNumber = 1;

  /**
   * Redundant variable, introduced for testability
   */
  let localVersion = env.VITE_APP_VERSION;

  /**
   * Migrates a client's localStorage (TSO-371) as follows:
   * - Rename localStorage "app-context" to "app-context@{mode}#{clientSlug}"
   * - Remove localStorage "cart" as it lives in sessionStorage from now on
   * @returns the appContext as read from the "app-context" localStorage.
   * @todo Remove once all clients in all environments (dev/staging/prod) have been migrated for sure.
   */
  const migrateLocalStorage = useCallback(() => {
    const appContextValue = window.localStorage.getItem('app-context');
    if (!appContextValue) return;

    try {
      const appContext = JSON.parse(appContextValue) as AppContext;

      if (!appContext?.client?.clientSlug)
        throw new Error('Renaming localStorage entry "app-context" failed: clientSlug is missing');

      // Note: The storage key below is taken over from the one spawned by the `useBrowserPersistence` hook.
      //       As this a temporary one-time migration routine, the code duplication is tolerable.
      window.localStorage.setItem(
        `app-context@${appContext.device.isKiosk ? 'k' : 'm'}#${appContext.client.clientSlug}`,
        appContextValue,
      );
      return {
        isKiosk: appContext.device.isKiosk,
        clientSlug: appContext.client.clientSlug,
        clientGuid: appContext.client.clientGuid,
      };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      Sentry.captureException(err);
      return undefined;
    } finally {
      window.localStorage.removeItem('app-context');
      window.localStorage.removeItem('cart');
    }
  }, []);

  /**
   * Apply pending migrations one by one (if any)
   * Note to devteam: cleanup old migrations (i.e.: old switch-cases below) every now and then
   */
  const applyMigrations = useCallback(() => {
    // find out in what "migration version" the running machine is, in order to determine which ones are pending
    const lastAppliedMigrationNumber: number = parseInt(window.localStorage.getItem('MIG_SEQ_NO') ?? '0', 10);

    let migrationAction = () => {};

    // apply pending migrations one by one (if any)
    for (let seqNumber = lastAppliedMigrationNumber + 1; seqNumber <= migrationSequenceNumber; seqNumber++) {
      switch (seqNumber) {
        // Migration 0→1: breaking changes in localStorage and routes.
        case 1:
          migrationAction = () => {
            const appContext = migrateLocalStorage();

            // routes were subjected to breaking changes, so ensure that client does not get stuck on a removed route
            if (appContext) {
              window.location.href = appContext.isKiosk
                ? generatePath(routes.kioskScreensaver, { clientSlug: appContext.clientSlug, mode: 'k' })
                : generatePath(routes.qr, { clientGuid: appContext.clientGuid });
            } else {
              // fail-safe pathway (re-signin needed)
              window.location.href = generatePath(routes.exit);
            }
          };
          break;

        // Migration 1→2:
        // <describe why and what and state the desired `migrationAction`>
        case 2:
          break;
      }

      // eslint-disable-next-line no-console
      console.info(`Applying migration ${lastAppliedMigrationNumber} → ${seqNumber}...`);
      window.localStorage.setItem('MIG_SEQ_NO', seqNumber.toString());
      migrationAction();
    }
  }, [migrateLocalStorage]);

  /**
   * Deploy a different (upgrade/downgrade) version
   * TODO: The user will loose their state that is not stored in localStorage.
   *       We should block reloading the page when the user is in the middle of an order.
   */
  const deploy = useCallback(
    async (remoteVersion: string) => {
      const logMessage = `Loading new release ("${localVersion}" → "${remoteVersion}")...`;
      // eslint-disable-next-line no-console
      console.info(logMessage);
      Sentry.captureMessage(logMessage, {
        extra: { currentVersion: localVersion, latestVersion: remoteVersion },
      });
      await Sentry.flush();

      await cacheManager.clearAllCaches();
      window.location.reload();
    },
    [cacheManager, localVersion],
  );

  /**
   * Assess whether deployment is to be triggered
   */
  const assess = useCallback(
    (polledRemoteVersion: string) => {
      // 1) Assess the remote version delta over time
      // Reason: prevent infinite poll→assess→deploy loops that may occur when the `polledRemoteVersion` and/or
      // the `VITE_APP_VERSION` are erroneous in any way, causing step 2 (below) to
      // trigger a deploy for the same build now as on the previous poll interval, as on the one before that, and so on...
      const isAlreadyAssessed = window.localStorage.LATEST_REMOTE_VERSION === polledRemoteVersion;
      if (isAlreadyAssessed) return;
      window.localStorage.setItem('LATEST_REMOTE_VERSION', polledRemoteVersion);

      // 2) Assess the remote-to-local delta
      if (polledRemoteVersion !== localVersion) {
        void deploy(polledRemoteVersion);
      }
    },
    [deploy, localVersion],
  );

  /**
   * Issue a GET `/version.json` httpRequest
   */
  const poll = useCallback(async () => {
    // GET version.json from remote
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const pollData: { version?: unknown } = await (await window.fetch('/version.json')).json();

    // postcondition check
    if (!pollData || !('version' in pollData) || typeof pollData.version !== 'string' || !pollData.version) {
      Sentry.captureException('Version check aborted due to erroneous "version.json" contents', {
        extra: { data: JSON.stringify(pollData) },
      });
      void Sentry.flush();
      return;
    }

    assess(pollData.version);
  }, [assess]);

  /**
   * Activate the deployment agent.
   * To be called as early as possible in app's lifecycle.
   */
  const engage = useCallback(
    (pollIntervalMinutes: number = DEFAULT_POLL_INTERVAL) => {
      try {
        setIsBusy(true);
        applyMigrations();
      } finally {
        setIsBusy(false);
      }

      // Activate the polling mechanism
      const minutesToMilliseconds = 60000;
      window.setInterval(() => void poll(), pollIntervalMinutes * minutesToMilliseconds);

      void poll(); // first poll right away
    },
    [applyMigrations, poll],
  );

  // Testing from the browser console
  // 1) a manually triggered regular `poll`         : window.testDeploymentAgent(false, false)
  // 2) act as if a new version was released        : window.testDeploymentAgent(true, true)
  // 3) assert behavior for infinite-loop prevention: window.testDeploymentAgent(false, true)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).testDeploymentAgent = (resetLocalVersion: boolean, resetRemoteVersion: boolean) => {
    if (resetLocalVersion) localVersion = '0.0.0';
    if (resetRemoteVersion) window.localStorage.setItem('LATEST_REMOTE_VERSION', '0.0.0');
    void poll();
  };

  return { engage, isBusy };
}
