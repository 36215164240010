/* eslint-disable hooks/sort */
import * as Sentry from '@sentry/react';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ZodSchema } from 'zod';

import { AppContext } from './useAppContext/appContext.schema';

export const PersistenceSpecification = {
  CacheManager: { key: 'cache-manager', isIsolated: false, storageMedium: 'localStorage' },
  AppContext: { key: 'app-context', isIsolated: true, storageMedium: 'localStorage' },
  Cart: { key: 'cart', isIsolated: true, storageMedium: 'sessionStorage' },
} as const;

type PersistenceSpecificationType = (typeof PersistenceSpecification)[keyof typeof PersistenceSpecification];

type IsolationSuffix = { mode: string; clientSlug: string };
const isolationSuffixKey = 'isolationSuffix';

/**
 * Handles reading and writing to browser persistence media.
 * Currently supported media are `localStorage` and `sessionStorage`.
 * Ensures that the persisted state is isolated, such that 1..n kiosks
 * and 1..n non-kiosks on 1..n clients can be used simultaneously without interference.
 * @param persistenceSpecification - specifies desired persistence behaviors
 * @param defaultValue - the default value to use if persisted state is not (yet) available
 * @param zodSchema - the zod schema the persisted state is validated against upon first read
 */
export const useBrowserPersistence = <T>(
  persistenceSpecification: PersistenceSpecificationType,
  defaultValue: T,
  zodSchema?: ZodSchema<T>,
): readonly [T, Dispatch<SetStateAction<T>>] => {
  const { clientSlug } = useParams<{ clientSlug: string | undefined }>();
  const { mode } = useParams<{ mode: string | undefined }>();

  /*
   * Persisted state is isolated by url params `mode` and `clientSlug`.
   * SessionStorage is used to mitigate not all routes specifying both these params.
   */
  const getIsolationSuffix = useCallback(() => {
    // read
    const storedValue = sessionStorage.getItem(isolationSuffixKey);
    const isolationSuffix = storedValue
      ? (JSON.parse(storedValue) as IsolationSuffix)
      : { mode: 'default', clientSlug: 'default' };
    // Note: The two 'default' values above are expected only for early app bootstrapping stages.
    //       String 'default' is arbitrary (is not used in any logic).

    // update from url params (url state prevails over stored state)
    if (clientSlug) isolationSuffix.clientSlug = clientSlug;
    if (mode) isolationSuffix.mode = mode;

    // write
    sessionStorage.setItem(isolationSuffixKey, JSON.stringify(isolationSuffix));

    return `@${isolationSuffix.mode}#${isolationSuffix.clientSlug}`;
  }, [clientSlug, mode]);

  /**
   * From the PersistenceSpecification, determine the applicable storage medium
   * and the unique key pointing to the data to read/write
   */
  const digestSpecification = useCallback(
    (specification: PersistenceSpecificationType) => {
      return {
        storage: window[specification.storageMedium],
        uniqueKey: specification.isIsolated ? `${specification.key}${getIsolationSuffix()}` : specification.key,
      };
    },
    [getIsolationSuffix],
  );

  /**
   *  From storage to memory
   */
  const readStorage = useCallback(
    (ps: PersistenceSpecificationType): string | null => {
      const { storage, uniqueKey } = digestSpecification(ps);
      return storage.getItem(uniqueKey);
    },
    [digestSpecification],
  );

  /**
   *  From memory to storage
   */
  const writeStorage = useCallback(
    <T>(ps: PersistenceSpecificationType, data: T | null) => {
      const { storage, uniqueKey } = digestSpecification(ps);
      if (data) {
        storage.setItem(uniqueKey, JSON.stringify(data));
      } else {
        storage.removeItem(uniqueKey);
      }
    },
    [digestSpecification],
  );

  const [storedValue, setStoredValue] = useState<T>(() => {
    // state initialization: if persisted state is available: read, parse and return it
    const storedString = readStorage(persistenceSpecification);

    // pathway 1 of 2: for first-time accessing a particular client
    if (!storedString) {
      writeStorage(persistenceSpecification, defaultValue);
      return defaultValue;
    }

    // pathway 2 of 2: for subsequent times accessing a particular client
    const storedObject = JSON.parse(storedString) as unknown;

    try {
      return zodSchema ? zodSchema.parse(storedObject) : (storedObject as T);
    } catch (err) {
      // This is an expected pathway for when devteam has made schema changes that
      // are breaking towards the `storedObject`.

      // eslint-disable-next-line no-console
      console.warn(err);
      Sentry.captureMessage(
        `Persisted value for ${persistenceSpecification.key} does not (any longer) comply to schema. Falling back to default value.`,
      );

      // Special handling for 'app-context' in case of  kiosk mode, for preventing sign-out:
      if (persistenceSpecification.key === 'app-context' && (storedObject as AppContext).device.isKiosk) {
        // Enrich the default value with just enough state from the stored value to prevent sign-out
        const defaultAppContext = defaultValue as AppContext;
        const storedAppContext = storedObject as AppContext;
        defaultAppContext.device.isKiosk = true;
        defaultAppContext.device.deviceId = storedAppContext.device.deviceId;
        defaultAppContext.device.virtualDeviceId = storedAppContext.device.virtualDeviceId;
        defaultAppContext.client.clientSlug = storedAppContext.client.clientSlug;
        defaultAppContext.client.refetch = true;
        writeStorage(persistenceSpecification, defaultAppContext);
        window.location.href = '/'; // to Root, will issue a new startup call, yielding a fresh (now parseable) app-context
        return defaultAppContext as T; // pro-forma return to satisfy linter
      }

      // Regular handling
      writeStorage(persistenceSpecification, defaultValue);
      return defaultValue;
    }
  });

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (action) => {
      setStoredValue((current) => {
        const newValue = action instanceof Function ? action(current) : action;

        try {
          writeStorage(persistenceSpecification, newValue);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          Sentry.captureException(err);
        }
        return newValue;
      });
    },
    [persistenceSpecification, writeStorage],
  );

  return [storedValue, setValue] as const;
};

/**
 * Read the isolationSuffix of the current session from sessionStorge
 */
const getCurrentIsolationSuffix = (): IsolationSuffix | null => {
  const isolationSuffix = sessionStorage.getItem(isolationSuffixKey);
  if (!isolationSuffix) return null;
  return JSON.parse(isolationSuffix) as IsolationSuffix;
};

/**
 * Wipe sessionStorage and the app-context localStorages
 */
export const removePersistedData = () => {
  Object.keys(localStorage)
    .filter((key) => key.startsWith('app-context'))
    .forEach((key) => localStorage.removeItem(key));

  sessionStorage.clear();
};

/**
 * Expose persisted data to Sentry
 */
export const getForSentry = (specification: PersistenceSpecificationType) => {
  try {
    const isolationSuffix = getCurrentIsolationSuffix();
    if (!isolationSuffix) return;

    const uniqueKey = specification.isIsolated
      ? `${specification.key}@${isolationSuffix.mode}#${isolationSuffix.clientSlug}}`
      : specification.key;

    // read and return the requested data
    return window[specification.storageMedium].getItem(uniqueKey);
  } catch (error) {
    return null;
  }
};
