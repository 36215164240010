import { useCallback, useEffect, useState } from 'react';

// If this signature is to be changed, adjust the sibling class in the APK sourcecode as well
interface ApkMetadata {
  appName: string;
  appSemVer: string;
}

/**
 * Proxy to the AndroidHost, for when running inside an APK.
 */
const useAndroidHost = () => {
  const [isAPKDevice, setIsAPKDevice] = useState<boolean>(false);
  const [apkMetadata, setApkMetadata] = useState<ApkMetadata>({ appName: 'unknown', appSemVer: 'unknown' });
  const [usbDeviceNames, setUsbDeviceNames] = useState<string[]>([]);

  const getApkMetadata = useCallback(() => {
    // backwards compatibility: the APK might be an older version, lacking the `getApkInfo` function
    if (!window.AndroidHost!.getApkInfo) return;
    window.AndroidHost!.getApkInfo('apkMetadata');
  }, []);

  const getUsbDeviceNames = useCallback(() => {
    // backwards compatibility: the APK might be an older version, lacking the `getApkInfo` function
    if (!window.AndroidHost!.getApkInfo) return;
    window.AndroidHost!.getApkInfo('usbDevices');
  }, []);

  const requestUsbPermission = useCallback((deviceName: string) => {
    // backwards compatibility: the APK might be an older version, lacking the `requestUsbPermission` function
    if (!window.AndroidHost!.requestUsbPermission) return;

    window.AndroidHost!.requestUsbPermission(deviceName);
  }, []);

  const printToUsb = useCallback((xmlPrintMessages: string[]): void => {
    // backwards compatibility: the APK might be an older version, lacking the `printToUsb` function
    if (!window.AndroidHost!.printToUsb) return;
    if (!xmlPrintMessages.length) return;

    window.AndroidHost!.printToUsb(xmlPrintMessages);
  }, []);

  const clearApkCache = useCallback((): void => {
    // backwards compatibility: the APK might be an older version, lacking the `clearCache` function
    if (!window.AndroidHost!.clearCache) return;

    // the APK call is blocking the thread, so when it returns the APK cache clearing has completed fully
    window.AndroidHost!.clearCache();
  }, []);

  useEffect(() => {
    if (!window.AndroidHost) {
      return;
    }

    setIsAPKDevice(true);

    // invoked by the APK
    window.apkInfoCallback = (parameterName: string, parameterValue: string) => {
      // backwards compatibility: the APK might be an older version, lacking handling for this particular parameter
      if (parameterValue === 'unknown parameter') return;

      switch (parameterName) {
        case 'apkMetadata':
          setApkMetadata(JSON.parse(parameterValue) as ApkMetadata);
          break;
        case 'usbDevices':
          window.AndroidHost!.writeToLog(parameterValue);
          setUsbDeviceNames(JSON.parse(parameterValue) as string[]);
          break;

        default:
          throw new Error(`Android callback for unhandled parameter "${parameterName}"`);
      }
    };

    // Clean up
    return () => {
      window.apkInfoCallback = undefined;
    };
  }, []);

  return {
    isAPKDevice,
    getApkMetadata,
    apkMetadata,
    getUsbDeviceNames,
    usbDeviceNames,
    requestUsbPermission,
    printToUsb,
    clearApkCache,
  };
};

export default useAndroidHost;
