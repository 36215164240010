const byId = <T extends { id: number }>(acc: Record<number, T>, item: T): Record<number, T> => {
  acc[item.id] = item;
  return acc;
};

/**
 * Reducer function that accumulates an array of objects into a new object where each key is the `id` of an object in the input array and the value is the corresponding object.
 * @param acc - The accumulator object.
 * @param item - The current item from the input array.
 * @returns The accumulator object with the current item added using the `id` property as the key.
 * @example
 * const arr = [{ id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }];
 * const result = groupByUniqueId.reduce(arr);
 * // result is { 1: { id: 1, name: 'Alice' }, 2: { id: 2, name: 'Bob' } }
 */
export const groupByUniqueId = <T extends { id: number }>(arr?: T[] | null): Record<number, T> =>
  arr?.reduce(byId, {} as Record<number, T>) ?? {};
