import * as Sentry from '@sentry/react';

import { Api } from '~/api-client';
import { allergenSchema, tagSchema } from '~/components/UI/Atoms/IconLabel/iconLabel.schema';
import { env } from '~/env';
import { notMaybe } from '~/utils/typeGuard.util';

import { searchableProductTypes } from './fuseOptions';
import { groupByUniqueId } from './groupByUniqueId.util';
import {
  Allergy,
  DiscountEvent,
  Inventory,
  Menu,
  PreparationChoice,
  Product,
  ProductChoice,
  ProductCollection,
  ProductTag,
  Section,
} from './Inventory.typings';
import { mapKeyToDictItem } from './inventoryMapping.util';

const ONE_HUNDRED_PERCENT = 100;

export default function parseInventory(data: Api.InventoryResult): Inventory {
  const depositsById = groupByUniqueId(data.deposits);
  const vatsById = groupByUniqueId(data.vats);

  const sectionsById =
    data.orderSections?.reduce(
      (acc, { subSections, ...orderSection }) => ({
        ...acc,
        [orderSection.id]: {
          ...orderSection,
          subSections:
            subSections
              ?.filter(({ productIds }) => productIds && productIds.length > 0)
              .map(({ productIds, ...subSection }) => ({
                ...subSection,
                products: [], // Skip products for now as it needs to reference productsById
              })) ?? [],
        },
      }),
      {} as Record<number, Section>,
    ) ?? {};

  // Filter out empty sections
  Object.keys(sectionsById).forEach((id) => {
    const sectionId = Number(id);
    if (sectionsById[sectionId]?.subSections.length === 0) {
      delete sectionsById[sectionId];
    }
  });

  const preparationChoicesById =
    data.preparationChoices?.reduce(
      (acc, { options, ...item }) => ({ ...acc, [item.id]: { ...item, options: options ?? [] } }),
      {} as Record<number, PreparationChoice>,
    ) ?? {};

  const productsById: Record<number, Product> = {};
  for (const {
    depositId,
    vatId,
    defaultSupplements,
    ingredients,
    preparationChoices,
    productChoices,
    productCollections,
    ...product
  } of data.products) {
    const isCustomizable =
      (productCollections && productCollections.length > 0) ||
      (ingredients && ingredients.length > 0) ||
      (productChoices && productChoices.length > 0) ||
      (preparationChoices && preparationChoices.length > 0) ||
      false; // Default to false (instead of `null`)

    const deposit = depositId !== null ? depositsById[depositId] : undefined;
    const vat = vatId !== null ? vatsById[vatId] : undefined;
    productsById[product.id] = {
      ...product,
      deposit,
      vat,
      subSection: undefined, // Skip in first iteration as it can only be added when sections are parsed
      defaultSupplements: [], // Skip in first iteration as it needs to reference productsById
      ingredients: [], // Skip in first iteration as it needs to reference productsById
      preparationChoices: mapKeyToDictItem(
        preparationChoices,
        'preparationChoiceId',
        preparationChoicesById,
        'extraPreparationChoice',
      ),
      type: isCustomizable ? 'product-customizable' : 'product-simple', // By default every product is a regular `product`, either customizable or not
      isUpgradableToMenu: false, // Default to false, will be set to true if it's part of the menu
      isPromoProduct: false, // Default to false, will be set to true if it's part of the promoProductCollection
      productChoices: [], // Skip in first iteration as it needs to reference productsById
      productCollections: [], // Skip in first iteration as it needs to reference productsById
      tags: [], // Skip in first iteration as it needs to reference productsById
      allergies: [], // Skip in first iteration as it needs to reference productsById
      discountEvent: undefined, // Skip in first iteration as it needs to reference productsById
      menu: [], // Skip in first iteration as it needs to reference productsById
      depositPrice: deposit?.price ?? 0,
      vatPrice: vat?.percentage !== undefined ? product.price * vat.percentage : 0, // Can be overwritten by discountEvent
      isSearchable: false, // Default to false, will be set to true if it's part of the allowedSearchableProducts
    };
  }

  const productCollectionsById =
    data.productCollections?.reduce(
      (acc, { extraProducts, ...item }) => ({
        ...acc,
        [item.id]: {
          ...item,
          extraProducts: mapKeyToDictItem(extraProducts, 'productId', productsById, 'product'),
        },
      }),
      {} as Record<number, ProductCollection>,
    ) ?? {};

  const productChoicesById =
    data.productChoices?.reduce(
      (acc, { options, ...item }) => ({
        ...acc,
        [item.id]: {
          ...item,
          options: mapKeyToDictItem(options, 'productId', productsById, 'product', 'ingredient-choice'),
        },
      }),
      {} as Record<number, ProductChoice>,
    ) ?? {};

  const allergies: Allergy[] =
    data.allergies?.map(({ productIds, ...item }) => {
      const products = productIds?.map((productId) => productsById[productId]).filter(notMaybe) ?? [];
      const allergy = { ...item, products };

      // Log a warning if the icon name is invalid, note that it cannot be omitted
      // as it should still be shown in the allergy list
      if (!allergenSchema.safeParse(allergy.iconName).success && !env.isDevelopment) {
        const msg = `Allergy(${allergy.id} - ${allergy.name}) has an invalid icon name.`;
        // eslint-disable-next-line no-console
        if (env.isDevelopment) console.warn(msg);
        else Sentry.captureMessage(msg);
      }

      // As a side-effect also attach the allergy to the products
      for (const product of products) {
        product.allergies.push(allergy);
      }

      return allergy;
    }) ?? [];

  const productTags: ProductTag[] =
    data.productTags?.map(({ productIds, ...item }) => {
      const products = productIds?.map((productId) => productsById[productId]).filter(notMaybe) ?? [];
      const productTag = { ...item, products };

      // Log a warning if the icon name is invalid, note that it cannot be omitted
      // as it should still be shown in the allergy list
      if (!tagSchema.safeParse(productTag.iconName).success) {
        const msg = `Allergy(${productTag.id} - ${productTag.name}) has an invalid icon name.`;
        // eslint-disable-next-line no-console
        if (env.isDevelopment) console.warn(msg);
        else Sentry.captureMessage(msg);
      }

      // As a side-effect also attach the tag to the products
      for (const product of products) {
        product.tags.push(productTag);
      }

      return productTag;
    }) ?? [];

  const discountEvents: DiscountEvent[] =
    data.discountEvents?.map(({ productIds, ...item }) => {
      const products = productIds?.map((productId) => productsById[productId]).filter(notMaybe) ?? [];
      const discountEvent: DiscountEvent = { ...item, products };

      // As a side-effect also attach the discountEvent to the products
      for (const product of products) {
        // Note that the last-one wins, the back-office will ensure a single discountEvent per product
        product.discountEvent = discountEvent;
        if (discountEvent.type === 'percentage') {
          product.discountedPrice =
            Math.round(product.price * (discountEvent.value / ONE_HUNDRED_PERCENT) * ONE_HUNDRED_PERCENT) /
            ONE_HUNDRED_PERCENT;
        } else if (discountEvent.type === 'fixed_amount') {
          product.discountedPrice =
            Math.round((product.price - discountEvent.value) * ONE_HUNDRED_PERCENT) / ONE_HUNDRED_PERCENT;
        } else if (discountEvent.type === 'free') {
          product.discountedPrice = 0;
        }
        // Update VAT price as it is based on the (discounted) price
        if (product.discountedPrice !== undefined) {
          product.vatPrice = product.discountedPrice * (product.vat?.percentage ?? 0);
        }
      }

      return discountEvent;
    }) ?? [];

  for (const { id, defaultSupplements, ingredients, productChoices, productCollections } of data.products) {
    const product = productsById[id];
    if (!product) continue; // Skip products that are not in the `productsById` (should not happen, but just in case)
    product.defaultSupplements = mapKeyToDictItem(defaultSupplements, 'productId', productsById, 'product');
    product.ingredients = mapKeyToDictItem(
      ingredients,
      'ingredientProductId',
      productsById,
      'ingredientProduct',
      'ingredient',
    );
    product.productChoices = mapKeyToDictItem(productChoices, 'productChoiceId', productChoicesById, 'extraProduct');
    product.productCollections = mapKeyToDictItem(
      productCollections,
      'productCollectionId',
      productCollectionsById,
      'productCollection',
      'supplement-from-collection',
    );
  }

  // Attach products to sections -> subSections
  const sections: Section[] = data.orderSections
    ?.map(({ id, subSections }) => {
      const section = sectionsById[id]; // Note that empty sections do not exist in `sectionsById`
      if (!section) return undefined;

      subSections?.forEach(({ productIds, id }) => {
        if (productIds === null) return;
        const subSection = section.subSections.find((subSection) => subSection.id === id);
        if (!subSection) return; // Note that empty subSections do not exist in `sectionsById[id].subSections`

        subSection.products =
          productIds
            .map((productId) => {
              const product = productsById[productId];
              if (!product) return undefined;
              product.subSection = subSection;
              return product;
            })
            .filter(notMaybe) ?? [];
      });

      return { ...section };
    })
    .filter(notMaybe);

  const menus: Menu[] =
    data.menus
      ?.map(({ collectionIds, defaultProductId, menuProductId }) => {
        const menuProduct = productsById[menuProductId];
        if (!menuProduct) return undefined;
        const defaultProduct = productsById[defaultProductId];
        if (!defaultProduct) return undefined;

        const newMenu: Menu = {
          defaultProduct,
          menuProduct,
          collections:
            collectionIds?.map((collectionId) => productCollectionsById[collectionId]).filter(notMaybe) ?? [],
        };

        // Side-effects

        // Attach the menu to the (default) product and mark it as "upgradable to menu"
        defaultProduct.menu = defaultProduct.menu.concat(newMenu);
        defaultProduct.isUpgradableToMenu = true;
        menuProduct.type = 'menu'; // Mark the menuProduct as a menu type
        menuProduct.menu = [newMenu];

        // In case the menuProduct options aren't set, use the defaultProduct options
        menuProduct.ingredients.length > 0 && (menuProduct.ingredients = defaultProduct.ingredients);
        menuProduct.productChoices.length > 0 && (menuProduct.productChoices = defaultProduct.productChoices);
        menuProduct.productCollections.length > 0 &&
          (menuProduct.productCollections = defaultProduct.productCollections);
        menuProduct.preparationChoices.length > 0 &&
          (menuProduct.preparationChoices = defaultProduct.preparationChoices);

        return newMenu;
      })
      .filter(notMaybe) ?? [];

  const promoProductsCollection =
    data.promoProductCollectionId !== null ? productCollectionsById[data.promoProductCollectionId] : undefined;

  const promoProducts =
    promoProductsCollection?.extraProducts.map((extraProduct) => {
      // Side effect; mark the product as a promo product
      extraProduct.product.isPromoProduct = true;

      return extraProduct.product;
    }) ?? [];

  const hasProductsWithStock = data.products.filter((product) => product.hasStock).length > 0;

  // Handle searchability of products
  const allowedSearchableProducts: Product[] =
    data.allowedSearchableProductIds
      .map((productId) => {
        const product = productsById[productId];
        if (!product) return undefined;

        if (!searchableProductTypes.some((type) => type === product.type)) {
          const msg = `Product(${product.id}) with type(${product.type}) should not searchable.`;
          // eslint-disable-next-line no-console
          if (!env.isProduction) console.warn(msg);
          Sentry.captureMessage(msg);
          return undefined;
        }

        product.isSearchable = true; // Mark the product as searchable
        return product;
      })
      .filter(notMaybe) ?? [];

  // This loop should, in theory, not be needed
  // Products in the (sub)sections should always be `product-simple`, `product-customizable` or `menu`
  // AND these products should not be reused as `supplement` or `ingredient`
  // however this last statement is not enforced, hence this check.
  for (const section of Object.values(sectionsById)) {
    for (const subSection of section.subSections) {
      for (const product of subSection.products) {
        if (product.type === 'product-simple') continue;
        if (product.type === 'product-customizable') continue;
        if (product.type === 'menu') continue;

        const msg = `Product(${product.id} - ${product.name}) in subSection is also used as \`${product.type}\`.`;
        // eslint-disable-next-line no-console
        if (env.isDevelopment) console.warn(msg);
        else Sentry.captureException(msg);

        // Correct the type back to `product-simple` or `product-customizable`
        const isCustomizable =
          (product.productCollections && product.productCollections.length > 0) ||
          (product.ingredients && product.ingredients.length > 0) ||
          (product.productChoices && product.productChoices.length > 0) ||
          (product.preparationChoices && product.preparationChoices.length > 0) ||
          false; // Default to false (instead of `null`)
        product.type = isCustomizable ? 'product-customizable' : 'product-simple';
      }
    }
  }

  return {
    allergies,
    discountEvents,
    menus,
    preparationChoicesById,
    productsById,
    productTags,
    hasProductsWithStock,
    promoProducts,
    promoProductsName: promoProducts.length > 0 ? promoProductsCollection?.name : undefined,
    sections,
    sectionsById,
    allowedSearchableProducts,
  };
}
