import i18n from 'i18next';

const currencyFn = (lng: string) =>
  new Intl.NumberFormat(lng, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
  });
let currency = currencyFn(i18n.language);

const moneyFn = (lng: string) =>
  new Intl.NumberFormat(lng, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
const money = moneyFn(i18n.language);

const dateFn = (lng: string) => new Intl.DateTimeFormat(lng, { year: 'numeric', month: '2-digit', day: '2-digit' });
let date = dateFn(i18n.language);

const dateTimeFn = (lng: string) =>
  new Intl.DateTimeFormat(lng, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
let dateTime = dateTimeFn(i18n.language);

i18n.on('languageChanged', (lng) => {
  currency = currencyFn(lng);
  date = dateFn(lng);
  dateTime = dateTimeFn(lng);
});

/** format WITH currency symbol, like "€ 12,95" */
export const formatCurrency = (input: string | number, dashedZeros = false) => {
  const result = currency
    .format(typeof input === 'string' ? parseFloat(input) : input)
    // replaces spaces after sign with a space
    .replace(/^(\D+)/, '$1 ')
    .replace(/\s+/, ' ');

  // replace zero's with a dash
  return dashedZeros ? result.replace(/\.00$/, '.-').replace(/,00$/, ',-') : result;
};

/** format WITHOUT currency symbol, like "12,95" */
export const formatMoney = (input: string | number) => {
  return money.format(typeof input === 'string' ? parseFloat(input) : input);
};

export const formatDate = (input: string | Date, includeTime = true) => {
  const result = includeTime ? dateTime : date;
  return result.format(typeof input === 'string' ? new Date(input) : input);
};

/**
 * Convert the `inputContent` into a string of fixed length by applying padding.
 * @param inputContent: an input string of any length (null/undefined-tolerant)
 * @param outputLength: the required total length of the output string (comprising of content + padding + trail)
 * @param align: 'left' yields postfix padding, 'right' yields prefix padding
 * @param paddingChar: the character to use for padding
 * @param trailLength: the number of padding characters to add at the end of the string
 */
export const padString = (
  inputContent: string | null | undefined,
  outputLength: number,
  align: 'left' | 'right',
  paddingChar: string,
  trailLength: number = 0,
): string => {
  // precondition check
  if (paddingChar.length !== 1) throw new Error('paddingChar must be a single character');

  // denullify
  let output = inputContent || '';

  // trailLength is always adhered to, so it diminishes the length available for content
  const contentLength = outputLength - trailLength;

  // truncate to prevent overflowing the required length
  output = output.substring(0, contentLength);

  // pad to reach the required length
  output = align === 'left' ? output.padEnd(contentLength, paddingChar) : output.padStart(contentLength, paddingChar);

  // add trailing spaces (if any)
  output += paddingChar.repeat(trailLength);

  return output;
};
