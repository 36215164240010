import { Api } from '~/api-client/api';

/**
 * The availability of a printer, upholds for all printer modes (usb, cloud, ip)
 */
export type PrinterAvailabilityType = 'initializing' | 'unavailable' | 'available';

/**
 * The availability of the printer pool.
 */
export type PrinterPoolAvailabilityType = PrinterAvailabilityType | 'emptypool';

/**
 * The busy status of the printer pool.
 */
export type PrinterPoolBusyStatusType = 'idle' | 'printing';

export interface PrinterContext {
  printerPoolBusyStatus: PrinterPoolBusyStatusType;
  printerPoolAvailability: PrinterPoolAvailabilityType;
  getPrinterAvailability: (printerId: string) => PrinterAvailabilityType;
  printOrder: (order: Api.FinalizedOrderSet, retry?: boolean) => Promise<void>;
  printTestMessage: (printerId: string) => Promise<void>;
  connectIpPrinter: (printerId: string) => Promise<void>;
}

const createEmptyFn =
  <T>(fnName: string) =>
  (): T => {
    // eslint-disable-next-line no-console
    console.warn(`PrinterContext.${fnName} called before initialized`);
    return {} as T;
  };

const createEmptyFnAsync =
  <T>(fnName: string) =>
  async (): Promise<T> =>
    new Promise((f) => {
      // eslint-disable-next-line no-console
      console.warn(`PrinterContext.${fnName} called before initialized`);
      f({ success: false } as T);
    });

export const emptyPrinterContext: PrinterContext = {
  printerPoolBusyStatus: 'idle',
  printerPoolAvailability: 'initializing',
  getPrinterAvailability: createEmptyFn('getPrinterAvailability'),
  printOrder: createEmptyFnAsync('printOrder'),
  printTestMessage: createEmptyFnAsync('printTestMessage'),
  connectIpPrinter: createEmptyFnAsync('connectIpPrinter'),
};
