/* eslint-disable react-refresh/only-export-components */
import { createContext, Dispatch, SetStateAction } from 'react';
import { z } from 'zod';

import { BottomBarProps } from '~/components/UI/Molecules/BottomBar/BottomBar';
import { HeaderProps } from '~/components/UI/Molecules/Header/Header';

const LayoutSchema = z.object({
  header: z.custom<HeaderProps>().optional(),
  bottomBar: z.custom<BottomBarProps>().optional(),
});
export type LayoutContext = z.output<typeof LayoutSchema>;

export const emptyLayoutContext: LayoutContext = {};

export interface LayoutContextValue {
  layoutContext: LayoutContext;
  setLayoutContext: Dispatch<SetStateAction<LayoutContext>>;
}

const createEmptyFn = (fnName: string) => () => {
  // eslint-disable-next-line no-console
  console.warn(`LayoutContext.${fnName} called before initialized`);
};

export const LayoutContextContext = createContext<LayoutContextValue>({
  layoutContext: emptyLayoutContext,
  setLayoutContext: createEmptyFn('setLayoutContext'),
});

LayoutContextContext.displayName = 'LayoutContext';
