import { ProductType } from '.';

/** Limit searchable products by products with these types. Filtered out in `parseInventory`. */
export const searchableProductTypes = [
  'product-simple',
  'product-customizable',
  'menu',
] as const satisfies ProductType[];

// options object for use of Fuse.js
// for more check https://www.fusejs.io/api/options.html
export const fuseOptions = {
  includeScore: true,
  includeMatches: true,
  // in which keys in Product are we going to search (default weight: 1)
  keys: [
    'name',
    'description',
    {
      name: 'nutritionalValues',
      weight: 2,
    },
    {
      name: 'subSection.name',
      weight: 2,
    },
    { name: 'ingredients.ingredientProduct.name' },
    { name: 'tags.name', weight: 2 },
  ],
  // prevent everything (value: 1) from being returned (default 0.6)
  threshold: 0.4,
};
