/* eslint-disable react-refresh/only-export-components */
import { FC } from 'react';

export const loaderSizes = ['small', 'large'] as const;
type LoaderSizeType = (typeof loaderSizes)[number];

import useMediaQuery from '~/hooks/useMediaQuery.hook';

import Typography from '../Typography/Typography';
import LoaderIcon from './LoaderIcon';

const smallIconSize = 30;
const mediumIconSize = 60;
const largeIconSize = 120;

export interface LoaderProps {
  size?: LoaderSizeType;
  text?: string;
  fixed?: boolean;
}

const Loader: FC<LoaderProps> = ({ size = 'large', text = 'Loading...', fixed = true }) => {
  const isLargeScreen = useMediaQuery('lg');

  return (
    <div className={`${fixed ? 'fixed inset-0 h-screen w-screen' : ''} grid items-center justify-center`}>
      <div className="relative flex flex-col items-center justify-center gap-2">
        {size === 'large' ? (
          <LoaderIcon size={isLargeScreen ? largeIconSize : mediumIconSize} />
        ) : (
          <LoaderIcon size={isLargeScreen ? mediumIconSize : smallIconSize} />
        )}
        {text && (
          <Typography as="p" size={size === 'large' ? '3xl' : 'lg'} weight="bold">
            {text}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Loader;
