import z from 'zod';

const envVariables = z
  .object({
    VITE_APP_ENV: z.enum(['development', 'review', 'staging', 'production']),
    VITE_APP_VERSION: z.string(),
    VITE_NAME: z.string(),
    VITE_API_URL: z
      .union([z.string().url(), z.string().regex(/^\/.*$/)])
      .describe('URL to the API, either a full URL (starting with `http`) or a relative URL (starting with `/`).'),
    VITE_SENTRY_DSN: z.string().optional(),
    VITE_SEC_APPLICATION_ID: z.string(),
    VITE_POEDITOR_URL: z.string().url(),
    VITE_SIGNALR_URL: z.string(),
    VITE_DISABLE_PRINTER: z.coerce.boolean(),
    VITE_ENABLE_ACTIVITYTIMER: z.coerce.boolean(),
    VITE_FEATURE_FLAG_URL: z.string(),
  })
  .transform((env) => ({
    ...env,
    isDevServer: import.meta.env.DEV,
    isDevelopment: env.VITE_APP_ENV === 'development',
    isProduction: env.VITE_APP_ENV === 'production',
  }));

/** Environment variables parsed by Zod. */
export const env = envVariables.parse(import.meta.env);

/** Shortcut to sec application id from `env`. */
export const secApplicationId = env.VITE_SEC_APPLICATION_ID;
