import { Api } from '~/api-client';

export type ErrorType = 'checkout' | 'block' | 'generic' | 'printing' | 'init' | 'qr';

export type ApplicationError = {
  type: ErrorType;
  error?: Api.Error;
};

/**
 * function for providing a formatted error based on the type
 * @param type specific application based error type
 * @param error optional error object from the api
 * @returns ApplicationError object
 */
export const formatError = (type: ErrorType, error: Api.Error): ApplicationError => {
  return { type, error };
};

/**
 * Tackles the issue that, depending on the error type, a regular `JSON.stringify(...)` results in '{}'.
 */
export const stringifyDeep = (error: object | string) => {
  try {
    if (typeof error === 'string') return error;

    const result: { [key: string]: unknown } = {};

    Object.getOwnPropertyNames(error).forEach((prop) => {
      const value = (error as { [key: string]: unknown })[prop];

      if (typeof value === 'object' && value !== null) {
        result[prop] = stringifyDeep(value); // recursive call
      } else {
        result[prop] = value;
      }
    });

    return JSON.stringify(result, null, 2);
  } catch (e) {
    // not expected, just for fail-safity
    return 'Error while stringifying error object';
  }
};
