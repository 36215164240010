import { useEffect, useState } from 'react';

/**
 * Asynchronously loads a script file on demand
 * @param {boolean} isActive - For controlling when the loader should engage
 * @param {string} path - Path of the script file to load, relative to the webroot
 * @param onCompleted - Callback, will be invoked as soon as the script source is accessible
 */
const useScriptLoader = (isActive: boolean, path: string, onCompleted: () => void) => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (!isActive || isBusy || isCompleted) return;
    setIsBusy(true);

    const scriptElement = document.createElement('script');
    scriptElement.src = path;

    scriptElement.onload = () => {
      onCompleted();
      setIsCompleted(true);
      setIsBusy(false);
    };

    scriptElement.onerror = () => {
      setIsBusy(false);
      throw new Error(`failed to load "${path}"`);
    };

    document.body.appendChild(scriptElement);

    // Cleanup. Note: after removing <script> element from DOM the script itself lives on in browser memory
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [isActive, isBusy, isCompleted, onCompleted, path]);

  return { isCompleted: isCompleted };
};

export default useScriptLoader;
