import * as Sentry from '@sentry/react';
import { ZodiosPlugin } from '@zodios/core';

/** Custom error to more easily identify these kinds of errors in Sentry */
class ApiError extends Error {
  __proto__ = Error;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

/**
 * Plugin to intercept responses, validate them (even when response validation is turned off in prod) and
 * log invalid responses to Sentry.
 */
export const sentryPlugin: ZodiosPlugin = {
  name: 'sentry',
  response: async (api, config, res) => {
    const test = api?.find((q) => q.method === config.method && q.path === config.url)?.response.safeParse(res.data);
    if (test?.success === false) {
      const issuesArr = test.error.issues.map(({ path, ...rest }) => ({ ...rest, path: path.join('.') }));
      const issuesStr = issuesArr.map(({ code, ...rest }) => `${code}:${JSON.stringify(rest)}`).join('\n');
      Sentry.captureException(new ApiError(`${config.method} ${config.url}\n${issuesStr}`), {
        extra: {
          method: config.method,
          baseURL: config.baseURL,
          url: config.url,
          params: JSON.stringify(config.params),
          issues: issuesArr,
          response: res.data,
        },
      });
      await Sentry.flush();
    }
    return res; // Return the response so it can be used in the next plugin.
  },
};
