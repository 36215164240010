import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { env } from './env';
import { fallbackLng, languages } from './i18n.config';

export { fallbackLng };

/** Show literal i18n keys for development and testing */
export const cimode = !env.isProduction ? { cimode: { nativeName: 'CIMODE', culture: 'nl' } } : undefined;

export const languageItems = {
  ...languages,
  ...cimode,
};

export const languagesArr = Object.entries(languageItems).map(([code, { nativeName }]) => ({ code, nativeName }));

export const supportedLanguages = Object.keys(languageItems) as (keyof typeof languageItems)[];
export type SupportedLanguage = (typeof supportedLanguages)[number];

const ms = 1000;
const s = 60;
const m = 30;
const reloadIntervalTimer = ms * s * m; // 30 minutes for synchronization POEditor

const initializeI18n = async () => {
  await i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      supportedLngs: supportedLanguages,
      fallbackLng,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      backend: {
        // Any language that is not found in the first backend will be loaded from the second backend.
        // Missing keys within a language will *not* be loaded from the second backend.
        // -> This is technically possible, but requires always loading both JSON files (using the `i18next-resources-to-backend` plugin and then merging the results)
        backends: [
          HttpBackend, // Primary backend, configured to load the language from the poeditor URL
          HttpBackend, // Fallback backend, configured to load the language from the `/public/locales` folder
        ],
        backendOptions: [
          env.isDevServer
            ? {} // Don't use POEditor in development, because it's not yet updated with the latest translations
            : {
                reloadInterval: reloadIntervalTimer,
                loadPath: (lng: SupportedLanguage[]) => {
                  // because poeditor uses nl instead of nl-NL and en-gb instead of en-GB
                  const langCode = lng.length > 0 ? lng[0] : fallbackLng;
                  const poeditorLanguage = languageItems[langCode]?.culture.toLowerCase();
                  return `${env.VITE_POEDITOR_URL}/${poeditorLanguage}.json`;
                },
              },
          {}, // Default config for the fallback to load the `translation.json` file, if the language is not found in the first backend
        ],
      },
    });
};

initializeI18n().catch((error) => {
  // eslint-disable-next-line no-console
  console.error('Error initializing i18n:', error);
});

export default i18n;
