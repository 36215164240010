import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '~/hooks/useMediaQuery.hook';
import i18n from '~/i18n';

import LanguageIcon from '../../Atoms/LanguageSelector/LanguageIcon';
import Modal from '../../Atoms/Modal/Modal';
import Typography from '../../Atoms/Typography/Typography';

interface LanguageSelectorModalProps {
  languagesArr: {
    code: string;
    nativeName: string;
  }[];
  isModalOpen: boolean;
  handleModalClose: (lang?: string) => void;
}

const LanguageSelectorModal: FC<LanguageSelectorModalProps> = ({ languagesArr, isModalOpen, handleModalClose }) => {
  const { t } = useTranslation();
  const isMediumScreen = useMediaQuery('md');
  return (
    <Modal description={t('language-selector.title')} isOpen={isModalOpen} onClose={() => handleModalClose()}>
      <div className="flex w-full flex-col gap-5 md:gap-10">
        <Typography as="p" className="text-left" size="4xl" weight="bold">
          {t('language-selector.title')}
        </Typography>
        <div className="flex flex-col gap-3 md:gap-5">
          {languagesArr.map((language, i) => (
            <div
              className="flex items-center gap-3 hover:cursor-pointer md:gap-4"
              key={language.code}
              role="button"
              tabIndex={i}
              onClick={() => handleModalClose(language.code)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  handleModalClose(language.code);
                }
              }}
            >
              <LanguageIcon
                hasDarkBackground={false}
                language={language.code}
                selected={i18n.language === language.code}
                size={isMediumScreen ? 'medium' : 'small'}
              />
              <Typography
                as="p"
                className={i18n.language === language.code ? 'text-neutral-800' : 'text-neutral-400'}
                size="3xl"
                weight="medium"
              >
                {language.nativeName}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default LanguageSelectorModal;
