/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import classNames from 'classnames';
import { DetailedHTMLProps, FC, ImgHTMLAttributes, useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import { Api } from '~/api-client';
import localFallBackImage from '~/assets/twelve_fallback.png';
import { env } from '~/env';
import { useAppContext } from '~/hooks/useAppContext';

// Mapping to ensure the values are always in sync with the API
type ApiImageSizeMapping = {
  full: 0;
  _100: 100;
  _200: 200;
  _300: 300;
  _400: 400;
  _500: 500;
  _600: 600;
  _800: 800;
};
type ApiImageSize = ApiImageSizeMapping[Api.ImageSizes];

// Note `0` is a special value that means "original size"
export const apiImageSizes: readonly ApiImageSize[] = [100, 200, 300, 400, 500, 600, 800, 0];
export const imageRounding = ['full', '3xl', 'corners', 'none'] as const;
export type ImageRoundingOptions = (typeof imageRounding)[number];

/** From the actual consumed image width, infer what `ApiImageSize` is to be requested from the API */
const inferApiImageSize = (width = 0): ApiImageSize => {
  for (let i = 0; i < apiImageSizes.length; i++) {
    if (apiImageSizes[i] >= width) return apiImageSizes[i];
  }
  return 0; // Load the full size image
};

const imageRoundingClasses = {
  full: 'rounded-full',
  corners: 'rounded-t-lg rounded-b-lg rounded-l-lg rounded-r-lg',
  '3xl': 'rounded-3xl',
  none: 'rounded-none',
};

export interface ImgProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  img?: Omit<Api.ImageData, 'id'> | null;
  alt: string;
  rounded?: ImageRoundingOptions;
  /** if provided, this size will be used instead of size inference */
  fixedApiImageSize?: ApiImageSize;
}

const Img: FC<ImgProps> = ({ alt, img, rounded = 'none', fixedApiImageSize = null, ...props }) => {
  const {
    appContext: {
      client: { clientId, logo },
      device: { isKiosk },
    },
  } = useAppContext();

  const imgElementRef = useRef<HTMLImageElement>(null);
  const widths = useRef<{ largestLoaded: ApiImageSize | null; current: ApiImageSize | null }>({
    largestLoaded: null,
    current: null,
  });

  const image = useMemo(() => img ?? logo, [img, logo]);
  const imagePath = useMemo(() => `${env.VITE_API_URL}/${clientId}/image`, [clientId]);

  useLayoutEffect(() => {
    if (!imgElementRef.current) return;
    widths.current.current = fixedApiImageSize ?? inferApiImageSize(imgElementRef.current.clientWidth);
    const size =
      widths.current.largestLoaded === null
        ? widths.current.current
        : widths.current.largestLoaded === 0
          ? 0
          : Math.max(widths.current.largestLoaded, widths.current.current);
    const cacheOptOut = isKiosk ? '?no-cache=1' : '';
    imgElementRef.current.src = `${imagePath}/${image?.guid}/${size}${cacheOptOut}`;
  }, [fixedApiImageSize, image?.guid, imagePath, isKiosk]);

  useEffect(() => {
    const imgElement = imgElementRef.current;
    // Timeout of 0 to schedule the (fallback) image override to be run asynchronously. Necessary to properly render images in overlay(s) on Android
    const timeoutId = setTimeout(() => {
      if (!imgElement) return;
      widths.current.current = fixedApiImageSize ?? inferApiImageSize(imgElement.clientWidth);
      const size =
        widths.current.largestLoaded === null
          ? widths.current.current
          : widths.current.largestLoaded === 0
            ? 0
            : Math.max(widths.current.largestLoaded, widths.current.current);
      const cacheOptOut = isKiosk ? '?no-cache=1' : '';
      imgElement.src = `${imagePath}/${image?.guid}/${size}${cacheOptOut}`;
    }, 0);

    const setStaticFallbackImage = () => {
      if (!imgElement || image) return;
      imgElement.src = localFallBackImage;
    };

    const removeBackgroundImage = () => {
      if (!imgElement) return;
      imgElement.style.background = 'transparent';
    };

    imgElement?.addEventListener('error', setStaticFallbackImage);
    imgElement?.addEventListener('load', removeBackgroundImage);

    return () => {
      clearTimeout(timeoutId);

      imgElement?.removeEventListener('error', setStaticFallbackImage);
      imgElement?.removeEventListener('load', removeBackgroundImage);
    };
  }, [image?.guid, imagePath, image, isKiosk, fixedApiImageSize]);

  return (
    <img
      alt={alt}
      height={image?.height}
      width={image?.width}
      {...props}
      className={classNames(
        rounded && imageRoundingClasses[rounded],
        image.height ? image.height : 'h-full',
        image.width ? image.width : 'w-full',
        'bg-white object-contain',
        props.onClick !== undefined && 'cursor-pointer',
        image?.guid === logo?.guid && logo?.guid !== img?.guid && 'opacity-20',
        props.className,
      )}
      ref={imgElementRef}
      style={{
        ...props.style,
      }}
    />
  );
};

export default Img;
