import { CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef } from 'react';

import Alert from '../../Atoms/Alert/Alert';

declare module 'notistack' {
  interface VariantOverrides {
    default: false;
    error: false;
    success: {
      description?: string;
    };
    info: {
      description?: string;
    };
    warning: {
      description?: string;
    };
    danger: {
      description?: string;
    };
  }
}

interface ReportCompleteProps extends CustomContentProps {
  description: string;
  message: string;
}

/**
 * Don't use this component directly. Use `enqueueSnackbar` from `notistack` to call this snackbar.
 */
const Snackbar = forwardRef<HTMLDivElement, ReportCompleteProps>((props, ref) => {
  return (
    <SnackbarContent className="w-full justify-center opacity-95 shadow-sm" ref={ref} role="alert">
      <Alert message={props.description} title={props.message} type={props.variant} />
    </SnackbarContent>
  );
});

export default Snackbar;
