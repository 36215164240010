import { Check } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FC } from 'react';

import { badgeSizes } from './BadgeConstants';

export type BadgeSizeType = (typeof badgeSizes)[number];

// Component
export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: 'primary' | 'black';
  children: string;
  size?: BadgeSizeType;
  isChecked?: boolean;
}

// Utility functions
const getSizeClasses = (size: BadgeProps['size'], isChecked: boolean, length: number): string => {
  const isLongText = length > 2;

  switch (size) {
    case 'large': {
      if (isChecked) return 'size-5';
      return `h-8 p-1 ${isLongText ? 'text-sm' : 'text-lg'} leading-6 size-6`;
    }
    case 'small-autostretch': {
      return `h-6 p-1 ${isLongText ? 'text-xs' : 'text-sm'} leading-tight size-fit`;
    }
    case 'small':
    default: {
      if (isChecked) return 'size-5';
      return `h-6 p-1 ${isLongText ? 'text-xs' : 'text-sm'} leading-tight size-6`;
    }
  }
};

const BASE_CLASSES = 'font-extrabold rounded-3xl justify-center items-center text-center inline-flex';

/**
 * Primary UI component for Badge
 */
const Badge: FC<BadgeProps> = ({
  size = 'small',
  color = 'primary',
  children,
  className,
  style,
  isChecked = false,
  ...props
}) => {
  const colorClass = color === 'black' ? 'bg-black text-white' : 'bg-primary text-contrast ';
  const sizeClass = getSizeClasses(size, isChecked, children.length);

  return (
    <div
      className={classNames(sizeClass, BASE_CLASSES, colorClass, className)}
      {...props}
      style={{ ...style, minWidth: isChecked ? '' : '1.75em' }}
    >
      {isChecked ? <Check size={14} weight="bold" /> : children}
    </div>
  );
};

export default Badge;
