import { notMaybe } from '~/utils/typeGuard.util';

import { ProductType } from '.';

/**
 * Maps an array of objects to an array of objects with a new key based on a dictionary.
 *
 * @example
 * const arr = [{ id: 1, nameId: 1 }, { id: 2, nameId: 2 }];
 * const namesById = { 1: 'Alice', 2: 'Bob' };
 * const result = mapKeyToDictItem(arr, 'nameId', namesById, 'name');
 * // result is [{ id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }]
 */
export const mapKeyToDictItem = <
  T,
  U extends object | null | undefined,
  OriginKey extends string & keyof T,
  ResultKey extends string,
>(
  arr: T[] | readonly T[] | undefined | null,
  key: OriginKey,
  dict: Record<number, U>,
  mappedKey: ResultKey,
  /** Provide a type that the mappedItem should get as the `type` property. (only for products) */
  type?: ProductType,
): (Omit<T, OriginKey> & Record<ResultKey, U>)[] => {
  if (!arr) return [];
  return arr
    .map((item): undefined | (Omit<T, OriginKey> & Record<ResultKey, U>) => {
      const { [key]: itemKeyId, ...itemWithoutKey } = item;
      if (!itemKeyId) return undefined;
      const mappedItem = dict[itemKeyId as number];
      if (type && mappedItem !== null && mappedItem !== undefined && 'type' in mappedItem) {
        mappedItem.type = type;
      }
      return mappedItem
        ? ({ ...itemWithoutKey, [mappedKey]: mappedItem } as Omit<T, OriginKey> & Record<ResultKey, U>)
        : undefined;
    })
    .filter(notMaybe);
};
