const routes = {
  // -------------------------------------------------------------------------
  // SCAN AND GO only
  // -------------------------------------------------------------------------
  /** __SCAN & GO__: Start page */
  scanAndGoCart: '/:mode/:clientSlug/scanandgo/cart',
  scanAndGoScanner: '/:mode/:clientSlug/scanandgo/scanner',
  scanAndGoInAppPayment: '/:mode/:clientSlug/scanandgo/mobilepayment/:orderGuid',
  scanAndGoRelayPaymentStart: '/:mode/:clientSlug/scanandgo/startrelaypayment/:cartStashGuid', // for mobile
  scanAndGoRelayPaymentCart: '/:mode/:clientSlug/scanandgo/relaypaymentcart', // for kiosk, relay payment
  scanAndGoInAppPaymentCart: '/:mode/:clientSlug/scanandgo/inapppaymentcart', // for kiosk, in-app payment

  // -------------------------------------------------------------------------
  // KIOSK only
  // -------------------------------------------------------------------------
  /** __KIOSK only__: Screensaver (touch to order). */
  kioskScreensaver: '/:mode/:clientSlug/screensaver',
  /** __KIOSK only__: Service menu, sign in screen. */
  kioskServiceUnlock: '/:mode/:clientSlug/service/login',
  /** __KIOSK only__: Service menu, general tab. Requires extra pin by employee.*/
  kioskServiceGeneral: '/:mode/:clientSlug/service',
  /** __KIOSK only__: Service menu, service status tab. Requires extra pin by employee. */
  kioskServiceServiceStatus: '/:mode/:clientSlug/service/status',
  /** __KIOSK only__: Exit the service mode. Will set isTemporaryAdmin to false. */
  kioskServiceExit: '/:mode/:clientSlug/service/exit',
  /** __KIOSK only__: Exit the kiosk mode. Will set app in `non-kiosk-mode`. */
  kioskExit: '/:mode/:clientSlug/exit',
  /** __KIOSK only__: Select the kiosk to use when multiple kiosks are available. */
  kioskSelect: '/:mode/:clientSlug/select',
  /** __KIOSK only__: Service menu, receipt overview and reprint. */
  kioskPrintHistory: '/:mode/:clientSlug/service/printhistory',
  /** __KIOSK only__: blocked page when kiosk is blocked  */
  blocked: '/:mode/:clientSlug/blocked',
  // -------------------------------------------------------------------------
  // MOBILE only
  // -------------------------------------------------------------------------
  /** __MOBILE only__: PspPayment using Adyen. */
  checkoutStart: '/:mode/:clientSlug/checkout/pay/:orderGuid',

  // -------------------------------------------------------------------------
  // QR-code entries, will redirect to `start`.
  // -------------------------------------------------------------------------
  /** __QR entry__: scan&go on mobile. */
  qrScanAndGo: '/s/:clientGuid',
  /** __QR entry__: eat-in or take-away without table number. */
  qr: '/qr/:clientGuid',
  /** __QR entry__: eat-in with fixed table (isFixed === f) number or with variable (isFixed === v) table (tent) number */
  qrTableNumber: '/qr/:clientGuid/:isFixed/:tableIdOrTableTentId',

  // -------------------------------------------------------------------------
  // Shared (kiosk & mobile)
  // -------------------------------------------------------------------------
  /** Redirects to the client as stored in AppContext, or redirects to `kioskSignIn` when no client was previously set. */
  root: '/',
  /** One-time owner sign in by `clientSecret`. Will set app in `kiosk-mode` after sign in. Redirects to `start` when already signed in. */
  kioskSignIn: '/kiosk',
  /** Choose *eat-in* OR *take-away* if applicable, redirects to `sectionList` if not applicable for this `client`. */
  start: '/:mode/:clientSlug',
  /** Section list. */
  sectionList: '/:mode/:clientSlug/sections',
  /** Section. Sub-section (id) as query-param `?sub-section=123`. */
  section: '/:mode/:clientSlug/sections/:sectionId',
  /** Customizable products, customize to add to cart. `Make it a menu` option is an overlay. */
  customizableProduct: '/:mode/:clientSlug/customize/:productId',
  /** Configure menu to add to cart by the ID of the menu-product. */
  menu: '/:mode/:clientSlug/menu/:menuId/:applyProductToMenuUpgrade?',
  /** Cart. */
  cart: '/:mode/:clientSlug/cart',
  /** Assign the order to the name or (variable) table number, if required. Will redirect to `checkout` when not required or already known. */
  checkoutAssign: '/:mode/:clientSlug/cart/assign',
  /** Checkout. */
  checkout: '/:mode/:clientSlug/checkout',
  /** Pick up or Delivery time picker */
  pickupOrDeliveryInfo: '/:mode/:clientSlug/cart/pickup-or-delivery-info',
  /** __Payment provider return url__: Order processed by payment provider. */
  checkoutRedirectEvaluate: '/psp-adyen/confirm', // TODO: change to something like '/checkoutRedirectEvaluate' once RFC in TSO-107 is implemented.
  /** Follow up route for when checkoutRedirectEvaluate evaluated the payment as succeeded. */
  mobileCheckoutSuccess: '/:mode/:clientSlug/checkout-success',
  /** Now or Later  */
  nowOrLater: '/:mode/:clientSlug/now-or-later',
  /** PspPayment choice mobile, step 1: select PSP. */
  payment: '/:mode/:clientSlug/payment/:pspChoice?',
  /** PspPayment choice mobile, step 2: select issuer. */
  pspIssuer: '/:mode/:clientSlug/payment/:pspChoice/issuer',
  /** Client specific error page. */
  clientError: '/:mode/:clientSlug/error',
  /** General error page (without knowing the `clientSlug`). */
  error: '/error',
  /** Exit the app and clear the cache */
  exit: '/exit',

  /** Not found. */
  // notFound: '*', -> Not a route you can navigate to
} as const satisfies Record<string, `/${string}`>;

export type Routes = keyof typeof routes;
export default routes;
