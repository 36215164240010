import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { FC, PropsWithChildren, useMemo } from 'react';

import { env } from '~/env';

import { FeatureFlagKey } from './featureFlagKeys.constant';
import { FeatureFlagsContext } from './FeatureFlags';

const FEATURE_FLAG_POLLING_INTERVAL_MS = 300000; // 5 minutes

interface FeatureFlagResponse {
  features: { [key: string]: boolean };
}

/**
 * FeatureFlagsProvider, a provider for retrieving feature flags from Azure App Configuration.
 * See README.md for more information.
 */
export const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useQuery<FeatureFlagResponse>({
    queryKey: ['featureFlags'],
    queryFn: async () => {
      try {
        // TO DO: fetch feature flags from different Azure App Configuration instance for dev/test and for staging/production
        const response = await fetch(env.VITE_FEATURE_FLAG_URL);
        if (!response.ok) throw new Error(`HTTP error for feature flag fetch, status: ${response.status}`);
        return response.json();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Error fetching feature flags:', error);
        Sentry.captureMessage(`Feature flags could not be fetched from Azure App Configuration: ${error}`);
        throw error;
      }
    },
    refetchInterval: FEATURE_FLAG_POLLING_INTERVAL_MS,
    refetchOnReconnect: true, // Refetch when the network reconnects after an outage
    refetchIntervalInBackground: true, // Refetch even when the tab is in the background
  });

  const featureFlags = useMemo(() => data?.features ?? {}, [data]);

  const isFeatureFlagEnabled = (key: FeatureFlagKey) => {
    // Explicitly check for true or false which are the valid values for a feature flag
    // Feature flag may also be undefined which means the feature flag does not exist (for this environment)
    if (featureFlags[key] === true || featureFlags[key] === false) return featureFlags[key];
    else {
      // eslint-disable-next-line no-console
      console.warn(`Feature flag with key ${key} not found in Azure App Configuration, defaulting to false.`);
      Sentry.captureMessage(`Requested feature flag with key ${key} does not exist in Azure App Configuration`);
      return false;
    }
  };

  return (
    <FeatureFlagsContext.Provider
      value={{
        isFeatureFlagEnabled,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
