import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '~/hooks/useAppContext';
import { useInventory } from '~/hooks/useInventory';
import { fallbackLng, languagesArr } from '~/i18n';

import LanguageSelectorModal from '../../Molecules/LanguageSelectorModal/LanguageSelectorModal';
import LanguageIcon, { LanguageIconSizeType } from './LanguageIcon';

export interface LanguageSelectorProps {
  showCurrent?: boolean;
  showLabel?: boolean;
  hasDarkBackground?: boolean;
  size: LanguageIconSizeType;
}

const refetchInventoryTimeout = 200;

const LanguageSelector: FC<LanguageSelectorProps> = ({ showCurrent, showLabel, hasDarkBackground, size }) => {
  const { i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { refetch: refetchInventory } = useInventory();
  const {
    appContext: {
      client: { cultures },
    },
  } = useAppContext();

  const changeLanguage = useCallback(
    async (language: string) => {
      await i18n.changeLanguage(language);
      // somehow only the first time, when changing the language, a small delay is needed to refetch the inventory
      setTimeout(() => {
        void refetchInventory?.();
      }, refetchInventoryTimeout);
    },
    [i18n, refetchInventory],
  );

  const handleModalClose = useCallback(
    async (lang?: string) => {
      if (lang) await changeLanguage(lang);
      setIsModalOpen(false);
    },
    [changeLanguage],
  );

  // If there is only one language, change to that language
  useEffect(() => {
    if (cultures.length === 1) {
      void changeLanguage(cultures[0]);
    } else if (!cultures.includes(i18n.language)) {
      void changeLanguage(fallbackLng);
    }
  }, [cultures, changeLanguage, i18n.language]);

  const filteredLanguages = useMemo(() => {
    const subStrStart = 0;
    const subStrEnd = 3;
    return languagesArr.filter((lang) => {
      // cimode is a special language code that means "no localization"
      // it is only added in non-production environments and will not be available in production (APP_ENV=production)
      if (lang.code === 'cimode') return true;
      return cultures.some((culture) => culture.startsWith(lang.code.substring(subStrStart, subStrEnd)));
    });
  }, [cultures]);

  if (cultures.length <= 1) return null;

  if (showCurrent) {
    const languageName = filteredLanguages.find((lang) => lang.code === i18n.language)?.nativeName ?? '';
    return (
      <>
        <LanguageIcon
          hasDarkBackground
          label={showLabel ? languageName : ''}
          language={i18n.language}
          selected
          size={size}
          onClick={() => setIsModalOpen(true)}
        />
        <LanguageSelectorModal
          handleModalClose={handleModalClose}
          isModalOpen={isModalOpen}
          languagesArr={filteredLanguages}
        />
      </>
    );
  }

  return (
    <div className="flex gap-3">
      {cultures.map((language) => (
        <LanguageIcon
          hasDarkBackground={hasDarkBackground}
          key={language}
          language={language}
          selected={i18n.language === language}
          size={size}
          onClick={() => changeLanguage(language)}
        />
      ))}
    </div>
  );
};

export default LanguageSelector;
