import { useState } from 'react';

import useAndroidHost from './useAndroidHost';

interface Coords {
  x: number;
  y: number;
}

/**
 * Evaluates whether user intention is "Swipe" or "Click".
 * Invokes clickAction when intention is evaluated as "Click".
 * @param clickAction primary click action
 * @returns handlers for touch and mouse events
 */
export const useHandleTouch = (clickAction?: () => void) => {
  const { isAPKDevice: isTouchDevice } = useAndroidHost();
  const [coordsStart, setCoordsStart] = useState<Coords>({ x: -9, y: -9 });

  const start = (coords: Coords) => {
    if (!clickAction) return;

    setCoordsStart(coords);
  };

  const finish = (coords: Coords) => {
    if (!clickAction) return;

    const deltaX = Math.abs(coordsStart.x - coords.x);
    const deltaY = Math.abs(coordsStart.y - coords.y);

    // if finger/mouse movement exceeds this number of pixels: user intention is assumed to be Swipe; otherwise: Click.
    const clickSwipeDemarcation = 20;

    const assumeSwipeIntention = deltaX > clickSwipeDemarcation || deltaY > clickSwipeDemarcation;

    if (assumeSwipeIntention) return;

    setTimeout(() => {
      clickAction();
    }, 0);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (isTouchDevice) return;
    start({ x: e.clientX, y: e.clientY });
  };
  const handleMouseUp = (e: React.MouseEvent) => {
    if (isTouchDevice) return;
    finish({ x: e.clientX, y: e.clientY });
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (!isTouchDevice) return;
    start({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };
  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!isTouchDevice) return;
    finish({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY });
  };

  return { handleMouseUp, handleMouseDown, handleTouchStart, handleTouchEnd };
};
