/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Api } from '~/api-client';
import { ReceiptCollectionModel, ReceiptLineModel, ReceiptModel } from '~/hooks/useReceiptBuilder/ReceiptModeling';
import { padString } from '~/utils/format.util';

import { EPosBuilder } from './epsonTypes';

// #region configuration

/**
 * invariant parameters for controlling the layout of an epos print
 */
const layout = {
  /** Height of the receipt logo (width is calculated by the logo's aspect ratio) */
  logoHeight: 100,
  /** The number of characters that fit on one line, when default font is used. */
  lineWidth: 48,
  /** For mimicing a column layout, a fixed text length per "column" is imposed. Sum of all lengths must equal `lineWidth`. */
  columnWidths: {
    col1: 4,
    col2: 32,
    col3: 6,
    col4: 6,
  },
  /** For keeping distance between columns */
  columnSpacer: 1,
  /** For debugging the layout, consider using '.' temporarily */
  paddingChar: ' ',
  /** Ensure layout is valid */
  validate: () => {
    const totalWidth = Object.values(layout.columnWidths).reduce((acc, curr) => acc + curr, 0);
    if (totalWidth !== layout.lineWidth)
      throw new Error(`Sum of columnWidths equals ${totalWidth} while expected: ${layout.lineWidth}`);
  },
} as const;

/** Images can be rendered as a base64 string (for USB printer) or as raster images (for IP printer) */
enum ImageRenderMode {
  base64,
  raster,
}

// #endregion

/**
 * Transforms a `ReceiptCollectionModel` into an array of XML strings that can be sent to an Epson printer.
 */
export class EposXmlGenerator {
  /** @see postProcessLogoCheatNode */
  private static readonly LOGO_CHEATNODE = '##LOGO_CHEATNODE##';
  private readonly imageRenderMode: ImageRenderMode;

  private eposBuilder!: EPosBuilder;
  private xmlMessagesResult: string[] = [];

  private constructor(imageRenderMode: ImageRenderMode) {
    layout.validate();
    this.imageRenderMode = imageRenderMode;
  }

  // #region public static methods

  /**
   * Returns an array of XML strings that can be sent to an IP printer
   * @param receiptCollection: the receipt(s) to generate xmlmessage(s) for
   */
  public static async generateForIpPrinter(receiptCollection: ReceiptCollectionModel): Promise<string[]> {
    const instance = new EposXmlGenerator(ImageRenderMode.raster);
    await instance.digestReceiptCollection(receiptCollection);
    return instance.xmlMessagesResult;
  }

  /**
   * Returns an array of XML strings that can be sent to an USB printer
   * @param receiptCollection: the receipt(s) to generate xmlmessage(s) for
   */
  public static async generateForUSBPrinter(receiptCollection: ReceiptCollectionModel): Promise<string[]> {
    const instance = new EposXmlGenerator(ImageRenderMode.base64);
    await instance.digestReceiptCollection(receiptCollection);
    return instance.xmlMessagesResult;
  }

  // #endregion

  // #region private main methods

  /** Execute epos commands for the ReceiptCollection */
  private async digestReceiptCollection(receiptCollection: ReceiptCollectionModel) {
    for (const r of receiptCollection.receipts) {
      // eslint-disable-next-line no-await-in-loop
      await this.digestReceipt(r);
    }
  }

  /** Execute epos commands for ReceiptCollection → Receipt */
  private async digestReceipt(receipt: ReceiptModel): Promise<void> {
    this.eposBuilder = new window.epson!.ePOSBuilder();
    this.setFontType();

    this.setFontStyle(/* isBold: */ true);
    this.setAlignment('center');

    await this.writeLogo(receipt.logo);
    this.writeLinefeed();
    this.writeLine(receipt.nowOrLaterText);
    this.writeLine(receipt.orderTypeText);
    this.writeLine(receipt.pickupOutletName);
    this.setAlignment();

    this.writeLine(receipt.orderDescription);
    this.writeLine(receipt.deliveryTimeslotText);
    this.setFontStyle();
    this.writeLinefeed();

    receipt.receiptLines.forEach((rl) => this.digestReceiptLine(rl));

    this.writeLinefeed(2);

    if (receipt.deposit) {
      this.writeRow(null, receipt.deposit.label, null, receipt.deposit.amountText);
      this.writeLinefeed();
    }

    this.writeLine('-'.repeat(layout.lineWidth));
    this.setFontStyle(/* isBold: */ true);
    this.writeRow(null, receipt.grandTotal.label.toUpperCase(), null, receipt.grandTotal.amountText);
    this.setFontStyle();

    this.writeLinefeed(2);
    this.setAlignment('center');
    this.setFontStyle(/* isBold: */ true);
    this.writeLine(receipt.orderDateText);
    this.writeLine(receipt.orderNumberText);
    this.setFontStyle();
    this.setAlignment();

    this.writeLinefeed(3);
    this.writePaperCut();

    // get the resulting epos xmlstring
    let xmlString = this.eposBuilder.toString();

    // apply custom post-processing
    xmlString = await this.postProcessLogoCheatNode(xmlString, receipt.logo?.imageBase64);
    xmlString = this.postProcessRootNode(xmlString);

    this.xmlMessagesResult.push(xmlString);
  }

  /** Execute epos commands for ReceiptCollection → Receipt → ReceiptLine  */
  private digestReceiptLine(receiptLine: ReceiptLineModel): void {
    // count, description, unitprice, subtotal
    this.writeRow(
      receiptLine.countText,
      receiptLine.description,
      receiptLine.unitPriceText,
      receiptLine.subtotalPriceText,
    );

    // preparation choices
    receiptLine.preparationTexts.forEach((pc) => this.writeLine(pc));

    // excluded ingredients
    this.writeLine(receiptLine.excludedIngredientsText);

    // supplements
    receiptLine.supplements.forEach((supplement) => {
      this.writeRow(null, supplement.description, supplement.extraPriceText, supplement.subtotalPriceText);
      this.writeLinefeed(1);
    });
  }

  // #endregion

  // #region private supporting methods

  /**
   * Font A is the default, B is smaller, C even smaller.
   * When changing the font, also adjust `lineWidth` in the layout configuration.
   */
  private setFontType() {
    this.eposBuilder.addTextFont(this.eposBuilder.FONT_A);
  }

  /**
   * The styles set by this method uphold until the next invocation to this method.
   * for resetting to default: invoke without specifying any arguments
   **/
  private setFontStyle(
    isBold: boolean = false,
    invertColor: boolean = false,
    underline: boolean = false,
    color: string = this.eposBuilder.COLOR_1,
  ): void {
    this.eposBuilder.addTextStyle(invertColor, underline, isBold, color);
  }

  /**
   * The alignment set by this method upholds until the next invocation to this method.
   * for resetting to default: invoke without specifying any arguments
   **/
  private setAlignment(align: 'left' | 'center' | 'right' = 'left'): void {
    this.eposBuilder.addTextAlign(align);
  }

  private writeLine(text: string | null): void {
    if (!text) return;
    this.eposBuilder.addText(text);
    this.writeLinefeed();
  }

  private writeLinefeed(count: number = 1): void {
    this.eposBuilder.addFeedLine(count);
  }

  private writeRow(col1: string | null, col2: string | null, col3: string | null, col4: string | null) {
    this.writeColumnCell(col1, 'left', layout.columnWidths.col1, layout.columnSpacer);
    this.writeColumnCell(col2, 'left', layout.columnWidths.col2, layout.columnSpacer);
    this.writeColumnCell(col3, 'right', layout.columnWidths.col3, layout.columnSpacer);
    this.writeColumnCell(col4, 'right', layout.columnWidths.col4);
  }

  private writeColumnCell(text: string | null, textAlign: 'left' | 'right', colWidth: number, colSpacer: number = 0) {
    const paddedText = padString(text, colWidth, textAlign, layout.paddingChar, colSpacer);
    this.eposBuilder.addText(paddedText);
  }

  private writePaperCut(): void {
    this.eposBuilder.addCut(this.eposBuilder.CUT_FEED);
  }

  private async writeLogo(logo: Omit<Api.ImageData, 'id'> | null) {
    if (!logo?.imageBase64) return;

    switch (this.imageRenderMode) {
      case ImageRenderMode.base64:
        // consult the `postProcessLogoCheatNode` method for more information on this workaround
        this.eposBuilder.addText(EposXmlGenerator.LOGO_CHEATNODE);
        break;

      case ImageRenderMode.raster:
        {
          const { canvasContext, logoWidth, logoHeight } = await this.getSizedLogo(logo.imageBase64);

          this.eposBuilder.addImage(
            canvasContext,
            0,
            0,
            logoWidth,
            logoHeight,
            this.eposBuilder.COLOR_1,
            this.eposBuilder.MODE_MONO,
          );
        }
        break;

      default:
        throw new Error('Unsupported ImageRenderMode');
    }
  }

  /** resize image by adjusting the height to `layout.logoHeight`, width is calculated by the aspect ratio of the image. */
  private async getSizedLogo(logoBase64: string): Promise<{
    canvasContext: CanvasRenderingContext2D;
    logoWidth: number;
    logoHeight: number;
  }> {
    const logoImage = await this.loadImage(`data:image/png;base64,${logoBase64}`);

    // logoHeight is set, logoWidth follows from adhering to the aspect ratio
    const logoHeight = layout.logoHeight;
    const logoWidth = Math.floor(logoHeight * (logoImage.width / logoImage.height));

    // create canvas and draw the logo onto it, in the arrived at size
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d')!;
    canvas.width = logoWidth;
    canvas.height = logoHeight;
    canvasContext.drawImage(logoImage, 0, 0, logoWidth, logoHeight);

    return { canvasContext, logoWidth, logoHeight };
  }

  /** load an image in browser memory */
  private loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err);
    });
  }

  /**
   * For usb-printing (in contrast to ip-printing) we need to provide the image as a basic base64 string.
   * Hence we cannot use the `eposbuilder.addImage` method, but need to use a workaround.
   * The workaround is to call `eposbuilder.addText` (yielding a <text> node) with marker text `EposXmlGenerator.LOGO_CHEATNODE`,
   * and after the eposbuilder invocations are done, manipulate the resulting xml ourselves (here in this method).
   * The manipulation is: replace the <text> cheatnode with a proper <image> node.
   * @see LOGO_CHEATNODE
   * Note: a strategy with the same result has been implemented in the API (during work on TSO-344), as GET PrintData
   *       also returns different image formats for IP-prints (raster image) and USB-prints (base64 string).
   */
  private async postProcessLogoCheatNode(xmlString: string, logoBase64: string | null | undefined): Promise<string> {
    // exit if not applicable
    if (this.imageRenderMode !== ImageRenderMode.base64 || !logoBase64) return xmlString;

    const xmlDoc = new DOMParser().parseFromString(xmlString, 'application/xml');
    const cheatNode = this.findXmlTextNode(xmlDoc, EposXmlGenerator.LOGO_CHEATNODE);
    if (!cheatNode) throw new Error('Cheatnode not found in xmlString while a logo image is available');

    const { canvasContext, logoWidth, logoHeight } = await this.getSizedLogo(logoBase64);

    // replace the cheatnode with an <image> node that the APK understands
    const imageNode = xmlDoc.createElement('image');
    imageNode.textContent = canvasContext.canvas.toDataURL('image/png').replace('data:image/png;base64,', '');
    imageNode.setAttribute('width', logoWidth.toString());
    imageNode.setAttribute('height', logoHeight.toString());
    cheatNode.parentNode?.replaceChild(imageNode, cheatNode);

    return new XMLSerializer().serializeToString(xmlDoc);
  }

  /** in the `xmlDoc`, find a <text> node that has the specified `textContent` */
  private findXmlTextNode(xmlDoc: Document, textContent: string) {
    const textNodes = xmlDoc.getElementsByTagName('text');
    for (let i = 0; i < textNodes.length; i++) {
      if (textNodes[i].textContent === textContent) return textNodes[i];
    }
    return null;
  }

  /**
   * For some reason, the ip printer (not investigated for usb printer) returned a 'SchemaError' when sending a print command.
   * Appeared to be caused by the rootnode, so in this method we remove it. The xml returned by `GET PrintData` doesn't have a rootnode either.
   * Note: this is a weird bug, as the rootnode is generated by Epson's own SDK.
   */
  private postProcessRootNode(xmlString: string): string {
    const rootNode = '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">';

    // Due to the odd nature of this bug, we add this precondition check in case we might ever update the SDK to a version that exposes
    // different (improved or still erroneous) behavior
    if (!xmlString.includes(rootNode)) throw new Error('Rootnode not found in xmlString. Has the SDK been updated?');

    return xmlString.replace(rootNode, '').replace('</epos-print>', '');
  }

  // #endregion
}
