import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Api } from '~/api-client/api';
import { env } from '~/env';
import { serializeImage } from '~/utils/image.util';

import { useAppContext } from '../useAppContext';
import { useInventory } from '../useInventory';
import { BuildOptions, ReceiptBuilder } from './ReceiptBuilder';
import { ReceiptCollectionModel } from './ReceiptModeling';

/**
 * ReceiptLogo is not stored in AppContext but in a dedicated sessionStorage,
 * to not burden the AppContext with heavy data.
 */
const RECEIPTLOGO_STORAGE_KEY = 'receiptLogoBase64';

/**
 * Builds receipt(s) from an orderSet
 */
export const useReceiptBuilder = () => {
  const { preparationChoicesById } = useInventory();

  const {
    appContext: {
      client: { receiptLogo, clientId, clientName, takeOutCustomName },
      device: { selectedKiosk, isKiosk },
    },
  } = useAppContext();

  const {
    i18n: { language },
  } = useTranslation();

  /** As the API doesn't return the receiptLogo as base64 string, we need to create that string ourselves */
  const importReceiptLogo = async (imageGuid: string | null | undefined) => {
    let receiptLogoBase64 = null;

    if (imageGuid) {
      receiptLogoBase64 = await serializeImage(`${env.VITE_API_URL}/${clientId}/image/${imageGuid}/100?no-cache=1`);
    }

    if (receiptLogoBase64) sessionStorage.setItem(RECEIPTLOGO_STORAGE_KEY, receiptLogoBase64);
    else sessionStorage.removeItem(RECEIPTLOGO_STORAGE_KEY);
  };

  const buildReceiptCollection = useCallback(
    (orderSet: Api.FinalizedOrderSet, buildOptions: BuildOptions): ReceiptCollectionModel => {
      const receiptBuilder = new ReceiptBuilder(
        preparationChoicesById,
        language,
        clientName,
        // if there is a receiptLogo, replace its (not properly filled) imageBase64
        receiptLogo ? { ...receiptLogo, imageBase64: sessionStorage.getItem(RECEIPTLOGO_STORAGE_KEY) } : null,
        takeOutCustomName,
        isKiosk,
        selectedKiosk,
        buildOptions,
      );
      return receiptBuilder.run(orderSet);
    },
    [clientName, isKiosk, language, preparationChoicesById, receiptLogo, selectedKiosk, takeOutCustomName],
  );

  return { buildReceiptCollection, importReceiptLogo };
};
