import { useSyncExternalStore } from 'react';

function getSnapshot(): 'online' | 'offline' {
  return navigator.onLine ? 'online' : 'offline';
}

function getServerSnapshot(): 'online' {
  return 'online';
}

function subscribe(callback: () => void) {
  window.addEventListener('online', callback);
  window.addEventListener('offline', callback);
  return () => {
    window.removeEventListener('online', callback);
    window.removeEventListener('offline', callback);
  };
}

export function useNetworkState(): 'online' | 'offline' {
  return useSyncExternalStore<'online' | 'offline'>(subscribe, getSnapshot, getServerSnapshot);
}
