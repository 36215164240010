/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import {
  DetailedHTMLProps,
  DialogHTMLAttributes,
  FC,
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '~/hooks/useMediaQuery.hook';

const BASE_MODAL_CONTAINER_CLASSES = 'flex flex-col items-start rounded-lg bg-white shadow-lg w-full';
const KIOSK_DIALOG_CLASSES = 'max-w-screen-md w-full p-8 lg:p-0';
const MOBILE_DIALOG_CLASSES = 'w-[343px]';

export interface ModalProps extends DetailedHTMLProps<DialogHTMLAttributes<HTMLDialogElement>, HTMLDialogElement> {
  isOpen: boolean;
  onClose: () => void;
  description?: string;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ isOpen, onClose, children, description, ...props }) => {
  const { t } = useTranslation();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const isMediumScreen = useMediaQuery('md');
  const dialogClassNames = isMediumScreen ? KIOSK_DIALOG_CLASSES : MOBILE_DIALOG_CLASSES;

  const handleClose = useCallback(() => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
    onClose();
  }, [dialogRef, onClose]);

  const onClick = useCallback(
    (event: ReactMouseEvent<HTMLDialogElement, MouseEvent>) => {
      const { current: el } = dialogRef;
      if (event.target === el) handleClose();
    },
    [handleClose],
  );

  useEffect(() => {
    if (isOpen && dialogRef.current) {
      dialogRef.current.showModal();
    } else if (dialogRef.current) {
      dialogRef.current.close();
    }
  }, [isOpen]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <dialog
      aria-describedby="modalDescription"
      className={`${dialogClassNames} overflow-visible bg-transparent backdrop:bg-neutral-950 backdrop:opacity-75`}
      ref={dialogRef}
      onClick={onClick}
      onClose={onClose}
      onKeyDown={handleKeyDown}
      {...props}
    >
      <div className="relative">
        <div className="hidden" id="modalDescription">
          {description}
        </div>
        <div className={classNames('absolute', isMediumScreen ? 'right-[-46px] top-[-46px]' : 'right-[0] top-[-54px]')}>
          <button
            aria-label={t('dialog.button.close')}
            className="flex size-[46px] items-center justify-center gap-[10px] rounded-lg border border-neutral-300 bg-white p-[10px]"
            onClick={handleClose}
          >
            <X className="fill-black" size={24} weight="bold" />
          </button>
        </div>
        <div className={classNames(BASE_MODAL_CONTAINER_CLASSES, isMediumScreen ? 'p-10' : 'p-3')}>{children}</div>
      </div>
    </dialog>
  );
};

export default Modal;
