import UUID from 'pure-uuid';

import { Product } from '../useInventory';
import { Cart, CartProductGrouping, CartProductLine } from './cart.schema';

export const productToCartLine = (product: Product): CartProductLine => {
  return {
    id: product.id,
    name: product.name,
    type: product.type,
    price: product.price,
    deposit: product.deposit ?? null,
    count: 1,
    extraPrice: 0,
    depositPrice: product.depositPrice,
    discountedPrice: product.discountedPrice ?? null,
    takenFromProductCollectionId: null,
    takenFromProductChoiceId: null,
    takenFromIngredientId: null,
    supplements: [],
    preparationChoices: [],
    minimumAge: product.minimumAge,
  };
};

const uuidV4 = 4;
export const uuid = (): string => new UUID(uuidV4).format();

export const groupingInputToGrouping = (product: Product): CartProductGrouping => {
  return {
    lineId: uuid(),
    count: 1,
    products: [productToCartLine(product)],
    subTotalPrice: 0,
    depositPrice: 0,
    totalPrice: 0,
    menu: null,
    totalCount: 1,
    isUpgradableToMenu: product.isUpgradableToMenu,
    hasBeenUpgradedToMenu: false,
    hasBeenAddedToCart: false,
    highestMinimumAge: product.minimumAge,
  };
};

export const calculateProductGrouping = (grouping: CartProductGrouping): void => {
  // Reset calculated values
  grouping.totalCount = 0;
  grouping.subTotalPrice = 0;
  grouping.depositPrice = 0;
  grouping.totalPrice = 0;
  grouping.highestMinimumAge = 0;

  // menu price is only added once to the total price and the product prices are ignored
  if (grouping.menu !== null) {
    grouping.menu.extraPrice = 0; // reset calculated value
    grouping.subTotalPrice += grouping.menu?.price ?? 0;
    grouping.totalPrice += grouping.menu?.price ?? 0;
  }

  for (const product of grouping.products) {
    if (!product) continue;

    let supplementsTotalExtraPrice = 0;

    for (const supplement of product.supplements) {
      supplementsTotalExtraPrice += supplement.extraPrice ?? 0;
    }

    // take the discount price if defined, otherwise the normal price
    const productPrice = grouping.menu !== null ? 0 : product.discountedPrice ?? product.price;
    product.extraPrice = product.extraPrice ?? 0;
    const totalExtraPriceProduct = product.extraPrice + supplementsTotalExtraPrice;

    if (grouping.menu !== null) {
      grouping.menu.extraPrice = (grouping.menu.extraPrice ?? 0) + totalExtraPriceProduct;
    }

    const productSubTotal = productPrice + supplementsTotalExtraPrice + product.extraPrice;
    grouping.totalCount += 1;
    grouping.depositPrice += product.depositPrice;
    grouping.subTotalPrice += productSubTotal;
    grouping.totalPrice += productSubTotal + product.depositPrice;

    if (product.minimumAge > grouping.highestMinimumAge) {
      grouping.highestMinimumAge = product.minimumAge;
    }
  }
};

export const calculateCart = (cart: Cart): void => {
  // Reset calculated values
  cart.totalCount = 0;
  cart.subTotalPrice = 0;
  cart.depositPrice = 0;
  cart.totalPrice = 0;
  cart.highestMinimumAge = 0;

  for (const order of Object.values(cart.orders)) {
    // Reset calculated values
    order.totalCount = 0;
    order.subTotalPrice = 0;
    order.depositPrice = 0;
    order.totalPrice = 0;
    order.highestMinimumAge = 0;

    for (const grouping of order.productGrouping) {
      calculateProductGrouping(grouping);
      order.totalCount += grouping.count;
      order.totalPrice += grouping.totalPrice * grouping.count;
      order.depositPrice += grouping.depositPrice * grouping.count;
      order.subTotalPrice += grouping.subTotalPrice * grouping.count;

      if (grouping.highestMinimumAge > order.highestMinimumAge) {
        order.highestMinimumAge = grouping.highestMinimumAge;
      }
    }
    cart.totalCount += order.totalCount;
    cart.subTotalPrice += order.subTotalPrice;
    cart.depositPrice += order.depositPrice;
    cart.totalPrice += order.totalPrice;

    if (order.highestMinimumAge > cart.highestMinimumAge) {
      cart.highestMinimumAge = order.highestMinimumAge;
    }
  }
};
