import { useCallback, useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);
const screens = fullConfig.theme.screens;

/**
 * Hook for detecting breakpoint based on tailwind screen values, set in tailwind.config.js
 * @param breakpoint - the breakpoint to check for
 * @returns boolean
 */
export const useMediaQuery = (breakpoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl' = 'sm') => {
  const inMedia = window.matchMedia(`(min-width: ${screens[breakpoint]})`).matches;
  const [state, setState] = useState<boolean>(inMedia);

  const inMediaQuery = useCallback(() => {
    const result = window.matchMedia(`(min-width: ${screens[breakpoint]})`).matches;
    setState(result);
  }, [breakpoint]);

  useEffect(() => {
    window.addEventListener('resize', inMediaQuery);
    return () => window.removeEventListener('resize', inMediaQuery);
  }, [inMediaQuery]);

  return state;
};

export default useMediaQuery;
