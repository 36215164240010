import { FC } from 'react';

import { AppContextProvider } from '~/hooks/useAppContext/AppContextProvider';
import { CartProvider } from '~/hooks/useCart/CartProvider';
import AppLayout from '~/pages/layouts/AppLayout';

import { FeatureFlagsProvider } from './useFeatureFlags/FeatureFlagsProvider';

const ContextsProvider: FC = () => {
  return (
    <FeatureFlagsProvider>
      <AppContextProvider>
        <CartProvider>
          <AppLayout />
        </CartProvider>
      </AppContextProvider>
    </FeatureFlagsProvider>
  );
};

export default ContextsProvider;
