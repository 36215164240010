import classNames from 'classnames';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useAppContext } from '~/hooks/useAppContext';
import { useAppRouting } from '~/hooks/useAppRouting.hook';
import { useCart } from '~/hooks/useCart';
import { useLayoutContext } from '~/hooks/useLayoutContext';
import useMediaQuery from '~/hooks/useMediaQuery.hook';
import routes from '~/routes';
import { formatCurrency } from '~/utils/format.util';

import Basket from '../../Atoms/Basket/Basket';
import Button, { ButtonProps } from '../../Atoms/Button/Button';
import LanguageSelector from '../../Atoms/LanguageSelector/LanguageSelector';
import Typography from '../../Atoms/Typography/Typography';

interface BottomBarWrapperProps {
  isRounded: boolean;
  hasButtons?: boolean;
  hasServiceLink: boolean;
  hasBackground: boolean;
  onError?: () => void;
}

export interface BottomBarProps {
  /** button left will only be shown on mobile if hasCart is false */
  buttonLeftProps?: ButtonProps;
  buttonRightProps?: ButtonProps;
  hasCart?: boolean;
  isRounded?: boolean;
  hasBackground?: boolean;
  hasLanguage?: boolean;
  hasServiceLink?: boolean;
  cartTotalPrice?: number;
  cartItemLineAmount?: number;
  onError?: () => void;
}

const BASE_CLASSES = 'fixed inset-x-0 bottom-0 text-white z-[100]';

const getClasses = (isMediumScreen: boolean, hasButtons = false, isRounded: boolean, hasBackground = true) => {
  const roundedClasses = isRounded ? 'rounded-t-xl md:rounded-t-3xl' : '';
  const bgClasses = hasBackground ? 'bg-black' : '';
  let baseClasses = '';
  if (hasButtons) {
    baseClasses = 'flex flex-col justify-between px-3 pt-3 md:p-6';
  } else {
    baseClasses = isMediumScreen ? 'px-96 flex items-center justify-center' : ' flex items-center justify-center p-3';
  }

  return classNames(BASE_CLASSES, baseClasses, roundedClasses, bgClasses);
};

const BottomBarWrapper: FC<PropsWithChildren<BottomBarWrapperProps>> = ({
  children,
  hasButtons,
  isRounded,
  hasBackground,
  hasServiceLink,
  onError,
}) => {
  const {
    appContext: { client, device },
  } = useAppContext();
  const { t } = useTranslation();

  const { navigateToPath } = useAppRouting();

  const isMediumScreen = useMediaQuery('md');
  const wrapperClasses = useMemo(
    () => getClasses(!!isMediumScreen, hasButtons, isRounded, hasBackground),
    [hasButtons, isMediumScreen, isRounded, hasBackground],
  );

  const handleServiceMenuClick = () => {
    // when entering the service menu from the error flow, we need to reset the error boundary
    onError?.();

    navigateToPath(routes.kioskServiceGeneral, { clientSlug: client.clientSlug });
  };

  const heightClass = isMediumScreen ? 'h-64' : 'h-20';

  return (
    <>
      {hasBackground && <div className={heightClass} />}
      <div className={classNames(wrapperClasses, heightClass)}>
        {children}

        {hasServiceLink && device.isKiosk && (
          /* denying the servicemenu to unauthorized users is (for now) taken care of by a zero opacity */
          <div className="absolute bottom-6 right-6 z-20 opacity-0">
            <Button as="button" className="flex items-center gap-2 opacity-50" onClick={handleServiceMenuClick}>
              <Typography as="span" className="text-black" size="lg" weight="semibold">
                {t('bottom-bar.service-link')}
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const BottomBar: FC<BottomBarProps> = (props) => {
  const {
    layoutContext: { bottomBar },
  } = useLayoutContext();

  const {
    appContext: {
      client: { clientSlug },
    },
  } = useAppContext();
  const { generatePathWithMode } = useAppRouting();

  const { cart } = useCart();

  const {
    buttonLeftProps,
    buttonRightProps,
    hasCart,
    isRounded = true,
    hasBackground = true,
    hasLanguage = true,
    hasServiceLink = false,
    onError,
  } = { ...props, ...bottomBar };

  const isMediumScreen = useMediaQuery('md');

  const buttonSize = isMediumScreen ? 'large' : 'small';

  if (buttonLeftProps || buttonRightProps) {
    return (
      <BottomBarWrapper
        hasBackground={hasBackground}
        hasButtons
        hasServiceLink={hasServiceLink}
        isRounded={isRounded}
        onError={onError}
      >
        <div
          className={
            buttonLeftProps && buttonRightProps ? 'grid grid-cols-[auto_1fr] gap-3 md:grid-cols-[auto_1fr_auto]' : '' // If only one button, take full width
          }
        >
          {buttonLeftProps && (isMediumScreen || !hasCart) && (
            <div className={isMediumScreen ? 'min-w-[240px]' : ''}>
              <Button {...buttonLeftProps} fullWidth size={buttonSize} variant="delete-dark" />
            </div>
          )}
          {hasCart && (
            <div className="inline-flex min-w-[120px] justify-center md:justify-end md:pr-4">
              <NavLink
                className="inline-flex"
                to={cart.totalCount > 0 ? generatePathWithMode(routes.cart, { clientSlug: clientSlug ?? '' }) : '#'}
              >
                <Basket label={formatCurrency(cart.totalPrice)} quantity={cart.totalCount} size={buttonSize} />
              </NavLink>
            </div>
          )}
          {isMediumScreen && !hasCart && <div />}
          <div className="md:w-[300px] lg:w-[400px]">
            {buttonRightProps && <Button {...buttonRightProps} fullWidth size={buttonSize} variant="primary" />}
          </div>
        </div>
        {isMediumScreen && hasLanguage && (
          <div className="inline-flex">
            <LanguageSelector hasDarkBackground showCurrent showLabel size="small" />
          </div>
        )}
      </BottomBarWrapper>
    );
  }

  return (
    <BottomBarWrapper
      hasBackground={hasBackground}
      hasServiceLink={hasServiceLink}
      isRounded={isRounded}
      onError={onError}
    >
      {hasLanguage && <LanguageSelector hasDarkBackground size={isMediumScreen ? 'large' : 'small'} />}
    </BottomBarWrapper>
  );
};

export default BottomBar;
