import { Api } from '~/api-client';

import { Calculated, Cart, CartProductGroupingInput, Order } from './cart.schema';

const defaultTimingOption: Api.TimingOption = 'now';

const emptyCalculated: Calculated = {
  totalCount: 0,
  depositPrice: 0,
  subTotalPrice: 0,
  totalPrice: 0,
  highestMinimumAge: 0,
};

export const emptyProductGrouping: CartProductGroupingInput = {
  lineId: '00000000-0000-0000-0000-000000000000',
  count: 1,
  isUpgradableToMenu: false,
  hasBeenUpgradedToMenu: false,
  products: [],
  menu: null,
  hasBeenAddedToCart: false,
  ...emptyCalculated,
};

export const emptyDeliveryInfo: Api.DeliveryInfo = {
  name: '',
  eMail: '',
  phone: '',
  address: {
    street: '',
    housenumber: '',
    city: '',
    zipCode: '',
  },
  comments: '',
  timeSlot: '',
  deliveryOption: 'TakeOut',
  orderReference: '',
  eatInReference: null,
};
export const emptyOrder: Order = {
  outOfStock: [],
  productGrouping: [],
  delivery: emptyDeliveryInfo,
  ...emptyCalculated,
};

export const emptyCart: Cart = {
  currentActiveOrder: defaultTimingOption,
  orders: { [defaultTimingOption]: emptyOrder },
  ...emptyCalculated,
};
