import './i18n';
import './styles/global.css';

import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { useRegisterSW } from 'virtual:pwa-register/react';

import Loader from './components/UI/Atoms/Loader/Loader';
import { useDeploymentAgent } from './hooks/useDeploymentAgent.hook';
import router from './router';

const milliseconds = 1000;
const seconds = 60;
const minutes = 5;
const SERVICE_WORKER_CHECK_INTERVAL = milliseconds * seconds * minutes; // Check every 5 minutes

const App: FC = () => {
  const { t } = useTranslation();
  const { engage: engageDeploymentAgent, isBusy: isDeploymentAgentBusy } = useDeploymentAgent();

  // Auto update the service worker every 5 minutes
  useRegisterSW({
    onRegistered(r) {
      r &&
        setInterval(() => {
          void r.update();
        }, SERVICE_WORKER_CHECK_INTERVAL);
    },
  });

  useEffect(() => {
    engageDeploymentAgent();
  }, [engageDeploymentAgent]);

  return isDeploymentAgentBusy ? (
    <Loader text={t('loading')} />
  ) : (
    <RouterProvider fallbackElement={<Loader text={t('loading')} />} router={router} />
  );
};

export default App;
