import type { EPosBuilder, EPosDevice } from './epsonTypes';

export const EPOS_SDK_PATH = '/vendors/epos-2.27.0.js';

/**
 * A wrapper over ePOS-Print SDK for ip printing (12SO-agnostic, React-agnostic).
 * Introduced for isolation of multiple ip printers (TSO-485 and TSO-486).
 * Prerequisite: the ePOS-Print SDK script (EPOS_SDK_PATH) must have been loaded in global scope.
 */
export class EpsonIpPrinter {
  // #region private fields

  private eposBuilder: EPosBuilder | undefined = undefined;
  private eposDevice: EPosDevice;

  private id: string;
  private name: string;
  private ip: string;

  /**
   * Handles a Promise overarching the connect method and its two (nested) callback methods
   */
  private initializationPromiseHandler: null | {
    startTimestamp?: number;
    resolve: (value: void | PromiseLike<void>) => void;
    reject: (reason: string) => void;
  } = null;

  /**
   * @param port for http use 8008. When moving over to https use 8043.
   * @param timeOut In milliseconds. Hint to developer: choose lower value temporarily to assess proper timeout handling.
   */
  private static ConnectConfig = { port: 8008, timeOut: 20000 };

  // #endregion

  // #region constructors

  private constructor(id: string, name: string, ip: string) {
    this.id = id;
    this.name = name;
    this.ip = ip;

    this.eposDevice = new window.epson!.ePOSDevice();
  }

  // #endregion

  // #region public static members

  /**
   * Factory method
   */
  public static Create(id: string, name: string, ip: string): EpsonIpPrinter {
    return new EpsonIpPrinter(id, name, ip);
  }

  /**
   * Terminate all provided instances.
   */
  public static terminateAll(instances: EpsonIpPrinter[]): Promise<void[]> {
    // TODO: verify if this parallellism works (otherwise use the for-of loop with an await on each iteraction, with <eslint-disable-next-line no-await-in-loop>)
    return Promise.all(instances.map((p) => p.terminate()));
  }

  // #endregion

  // #region public instance members

  public get printerName(): string {
    return this.name;
  }

  public get printerIp(): string {
    return this.ip;
  }

  public get printerId(): string {
    return this.id;
  }

  /**
   * Connect, obtain eposDevice, obtain eposBuilder.
   */
  public async connect(): Promise<void> {
    const initializationPromise = new Promise<void>((resolve, reject) => {
      this.initializationPromiseHandler = {
        startTimestamp: Date.now(),
        resolve,
        reject: (reason: string) => {
          this.logToConsole(reason, true);
          reject(reason);
        },
      };

      this.logToConsole(`connecting to ${this.ip}:${EpsonIpPrinter.ConnectConfig.port}...`);
      this.eposDevice.CONNECT_TIMEOUT = EpsonIpPrinter.ConnectConfig.timeOut;

      this.eposDevice.connect(this.ip, EpsonIpPrinter.ConnectConfig.port, (result: string) =>
        this.connectCallback(result),
      );
    });

    try {
      await initializationPromise;
      window.addEventListener('beforeunload', () => this.terminate());
    } finally {
      this.logToConsole(`Initialization took ${Date.now() - this.initializationPromiseHandler!.startTimestamp!} ms`);
      this.initializationPromiseHandler = null;
    }
  }

  /**
   * Print the provided epos xmlmessages.
   */
  public async print(xmlPrintMessages: string[]): Promise<void> {
    // precondition check
    if (!this.eposBuilder) throw new Error(`ip printer "${this.name}": eposBuilder is not set`);

    for (const m of xmlPrintMessages) {
      // eslint-disable-next-line no-await-in-loop
      await this.sendMessage(m);
    }
  }

  /**
   * Close connection, dispose of the eposBuilder.
   */
  public terminate() {
    this.eposDevice.disconnect();
    this.eposBuilder = undefined;
    this.logToConsole('disconnected');
  }

  // #endregion

  // #region private methods

  /**
   * Start creating the epos device. Callback of the connection step.
   */
  private connectCallback(connectionResult: string): void {
    if (connectionResult !== 'OK' && connectionResult !== 'SSL_CONNECT_OK') {
      this.initializationPromiseHandler!.reject(`failed to connect, reason: ${connectionResult}`);
      return;
    }

    this.eposDevice.createDevice(
      'local_printer',
      this.eposDevice.DEVICE_TYPE_PRINTER,
      { crypto: false, buffer: false },
      (eposBuilder: EPosBuilder, retcode: string) => this.createDeviceCallback(eposBuilder, retcode),
    );
  }

  /**
   * Acquire the eposBuilder. (Nested) callback of the createEposBuilder step. Last step of initialization.
   */
  private createDeviceCallback(eposBuilder: EPosBuilder, retcode: string) {
    if (retcode !== 'OK') {
      this.initializationPromiseHandler!.reject(`failed to create eposBuilder, reason: ${retcode}`);
      return;
    }

    this.eposBuilder = eposBuilder;
    this.logToConsole('eposBuilder created');

    // TODO: attach desired event listeners. Consult https://thesio.atlassian.net/browse/TSO-281?focusedCommentId=52400)
    // Probably something like this:
    // this.eposBuilder.oncoveropen = () => ... ));

    this.initializationPromiseHandler!.resolve();
  }

  /**
   * Send the provided xmlString to the printer over http/https
   */
  private async sendMessage(xmlString: string): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      this.eposBuilder!.onreceive = (res) => {
        if (res.success) {
          this.logToConsole('print succeeded');
          resolve();
        } else {
          this.logToConsole(`print failed, code: '${res.code}'`, true);
          reject(res);
        }
      };

      this.logToConsole('printing...');
      this.eposBuilder!.setXmlString(xmlString);
      this.eposBuilder!.send();
    });
  }

  /**
   * Essential for debugging/testing
   */
  private logToConsole(message: string, isError = false) {
    /* eslint-disable no-console */
    console.info(`%c🖨️EpsonIpPrinter "${this.name}": ${message}`, `color:${isError ? 'red' : 'black'}`);
  }

  // #endregion
}
