import { ArrowsClockwise } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '~/hooks/useAppContext';
import { useAppRouting } from '~/hooks/useAppRouting.hook';
import { useCart } from '~/hooks/useCart';
import { useLayoutContext } from '~/hooks/useLayoutContext';
import routes from '~/routes';

import Button from '../../Atoms/Button/Button';
import Img from '../../Atoms/Img/Img';
import Typography from '../../Atoms/Typography/Typography';

interface BlockedProps {
  onReset?: () => void;
}

const Blocked: FC<BlockedProps> = ({ onReset }) => {
  const { t } = useTranslation();
  const { clearCart } = useCart();
  const { navigateToPath } = useAppRouting();
  const queryClient = useQueryClient();
  const {
    appContext: {
      client: { logo, lockScreenBg, clientSlug },
    },
  } = useAppContext();
  const { setLayoutContext } = useLayoutContext();

  const handleRefresh = () => {
    clearCart();
    queryClient.getQueryCache().clear();
    onReset?.();

    navigateToPath(routes.kioskExit, { clientSlug });
  };

  useLayoutEffect(() => {
    setLayoutContext({ bottomBar: { hasServiceLink: true, hasLanguage: false } });
  }, [setLayoutContext]);

  return (
    <div className="fixed inset-0 z-[1000] flex h-full flex-col items-center justify-center">
      {lockScreenBg ? (
        <div className="flex w-full flex-col items-center justify-center bg-white">
          <Img alt="" img={lockScreenBg} />
        </div>
      ) : (
        <div className="flex size-full flex-col items-center justify-center bg-white">
          <div className="flex max-w-[500px] flex-col items-center gap-8 p-14 text-center">
            <Img alt="logo" img={logo} style={{ height: '100px' }} />
            <Typography as="h1" className="pb-3 leading-10" size="4xl" weight="bold">
              {t('blocked-page.title')}
            </Typography>
            {onReset && (
              <Button
                as="button"
                size="large"
                startIcon={<ArrowsClockwise />}
                variant="secondary"
                onClick={handleRefresh}
              >
                {t('blocked-page.button')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Blocked;
