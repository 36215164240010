/**
 * @description Execute the specified `action`. If it fails, retry up to `maxAttempts` times.
 * @param action The action to execute
 * @param maxAttempts The maximum number of attempts. If the action fails on the last attempt, the caught error is rethrown.
 */
export const tryInvoke = async (action: () => Promise<void>, maxAttempts: number): Promise<void> => {
  let currentAttempt = 0;

  while (currentAttempt < maxAttempts) {
    try {
      currentAttempt++;
      // eslint-disable-next-line no-await-in-loop
      await action();
      return;
    } catch (error) {
      if (currentAttempt === maxAttempts) throw error;
    }
  }
};
