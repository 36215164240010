import { WarningCircle } from '@phosphor-icons/react';
import { FC, useMemo } from 'react';
import { FallbackProps, useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import Button from '~/components/UI/Atoms/Button/Button';
import Typography from '~/components/UI/Atoms/Typography/Typography';
import Blocked from '~/components/UI/Molecules/Blocked/Blocked';
import BottomBar from '~/components/UI/Molecules/BottomBar/BottomBar';
import { env } from '~/env';
import { useAppContext } from '~/hooks/useAppContext';
import { useAppRouting } from '~/hooks/useAppRouting.hook';
import routes from '~/routes';
import { ApplicationError, stringifyDeep } from '~/utils/errorHandling.util';

interface ErrorPageProps extends FallbackProps {
  error: ApplicationError;
  showButton?: boolean;
}

/**
 * ErrorPage component
 * if error is thrown from within errorBoundary user will need to reset before continuing.
 * based on the error type, the error page will show different messages and buttons
 */
const ErrorPage: FC<ErrorPageProps> = ({ error, resetErrorBoundary, showButton = true }) => {
  const { resetBoundary } = useErrorBoundary();
  const { t } = useTranslation();
  const {
    appContext: {
      client: { clientSlug },
    },
  } = useAppContext();
  const { navigateToPath } = useAppRouting();

  const canUserTryAgain = useMemo(() => showButton && error.type !== 'printing', [error.type, showButton]);

  const handleReturn = () => {
    resetErrorBoundary();

    if (error.type === 'checkout') {
      navigateToPath(routes.cart, { clientSlug });
    }
  };

  const { title, description } = useMemo(() => {
    let title, description;
    switch (error.type) {
      case 'init':
        title = t('error-page.print.title');
        description = t('error-page.print.description');
        break;
      case 'printing':
        title = t('error-page.checkout.title');
        description = t('error-page.checkout.description');
        break;
      case 'qr':
        title = t('error-page.qr.title');
        description = t('error-page.qr.description');
        break;
      default:
        title = t('error-page.title');
        description = t('error-page.description');
        break;
    }

    return { title, description };
  }, [error.type, t]);

  if (error.type === 'block') {
    return <Blocked onReset={resetErrorBoundary} />;
  }

  return (
    <div className="flex h-full flex-col items-center justify-center bg-neutral-100">
      <div className="flex max-w-[500px] flex-col items-center text-center">
        <WarningCircle className="text-black" height={180} width={180} />
        <div className="pt-6">
          <Typography as="h1" className="pb-3 leading-10" size="4xl" weight="bold">
            {title}
          </Typography>
          <Typography as="h2" className="text-neutral-500" size="2xl" weight="normal">
            {description}
          </Typography>
        </div>
        {canUserTryAgain && (
          <div className="pt-6">
            <Button as="button" size="large" variant="primary" onClick={handleReturn}>
              {t('error-page.button-try-again')}
            </Button>
          </div>
        )}
      </div>

      {env.isDevelopment && (
        <Typography
          as="div"
          className="m-4 h-auto overflow-y-scroll whitespace-pre-wrap font-mono text-red-500"
          size="base"
          weight="normal"
        >
          <b>Error (shown in DEV environment only):</b>
          <br />
          {stringifyDeep(error)}
        </Typography>
      )}

      <BottomBar hasLanguage hasServiceLink onError={resetBoundary} />
    </div>
  );
};

export default ErrorPage;
