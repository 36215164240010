import { DeepPartial } from 'react-hook-form';

/**
 * Deeply merge two or more objects. Arrays will be overwritten by the last value.
 *
 * @example
 * const obj1 = { a: { b: 1 }, d: 2 };
 * const obj2 = { a: { b: 3 }, d: 3 };
 * const obj3 = { d: 4 };
 *
 * const merged = merge(obj1, obj2, obj3);
 *
 * console.log(merged); // Output: { a: { b: 3 }, d: 4 }
 */
export function merge<T extends Record<string, unknown>, U extends DeepPartial<T>[]>(target: T, ...sources: U): T {
  const merged = { ...target };

  for (const source of sources) {
    for (const key in source) {
      if (!Object.prototype.hasOwnProperty.call(source, key)) continue;

      const sourceValue = source[key];
      const targetValue = merged[key];

      if (Array.isArray(sourceValue) && Array.isArray(targetValue)) {
        merged[key as keyof T] = [...sourceValue] as unknown as T[keyof T];
      } else if (sourceValue && typeof sourceValue === 'object' && typeof targetValue === 'object') {
        merged[key as keyof T] = merge(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          targetValue as Record<string, any>,
          sourceValue as Record<string, unknown>,
        ) as T[keyof T];
      } else {
        merged[key as keyof T] = sourceValue as unknown as T[keyof T];
      }
    }
  }

  return merged;
}
