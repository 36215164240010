import { fallbackLng } from '~/i18n';

import { AppContext } from './appContext.schema';

export const emptyClient: AppContext['client'] = {
  refetch: false,
  clientId: null,
  clientGuid: null,
  clientName: null,
  clientSlug: null,
  availableScanAndGoMode: null,
  defaultLanguage: fallbackLng,
  hasEatIn: false,
  hasBuyNowTakeLater: false,
  hasHomeDelivery: false,
  customerIdentificationMethod: 'eitherWay',
  hasPrinter: false,
  hasTakeOutRegular: false,
  hasTakeOutCustom: false,
  takeOutCustomName: null,
  takeOutCustomImage: null,
  idleScreenBg: null,
  lockScreenBg: null,
  inactivityDurationForDialogAppearance: 20,
  inactivityDurationForSessionReset: 10,
  virtualDevices: [],
  logo: {
    guid: null,
    height: 0,
    width: 0,
    imageBase64: null,
    extension: 'png',
    mimeType: 'image/png',
  },
  receiptLogo: {
    guid: null,
    height: 0,
    width: 0,
    imageBase64: null,
    extension: 'png',
    mimeType: 'image/png',
  },
  styles: {
    primaryColor: 'FA2837',
  },
  startMovie: null,
  cultures: [],
  openingTimes: [],
  todaysTimeSlots: [],
  promoBanner: null,
};

const emptyCustomer: AppContext['customer'] = {
  alternativeOrderIdentifier: null,
  alternativeOrderIdentifierType: null,
  orderType: null,
  selectedLanguage: null,
  mobileUserGuid: null,
  runtimeMode: null,
};

const emptyDevice: AppContext['device'] = {
  deviceId: null,
  isKiosk: false,
  isTemporaryAdmin: false,
  virtualDeviceId: null,
  selectedKiosk: null,
  printers: [],
  selectedVirtualDevice: null,
  prescribedScanAndGoMode: null,
};

export const emptyAppContext: AppContext = {
  client: emptyClient,
  customer: emptyCustomer,
  device: emptyDevice,
};
