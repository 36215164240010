import { z } from 'zod';

export const allergens = [
  'celery.png',
  'crustaceans.png',
  'egg.png',
  'fish.png',
  'gluten.png',
  'lupin.png',
  'milk.png',
  'molluscs.png',
  'mustard.png',
  'nuts.png',
  'peanuts.png',
  'sesame.png',
  'soya.png',
  'sulphite.png',
] as const;

export const tags = ['gluten-free.svg', 'good-deal-discount.svg', 'leaf.svg', 'shout-out.svg'] as const;

/** Zod schema to log a Sentry message during inventory parsing in `parseInventory`. */
export const tagSchema = z.enum(tags);
export const allergenSchema = z.enum(allergens);

export type Tags = z.infer<typeof tagSchema>;

export type Allergens = z.infer<typeof allergenSchema>;
