import { WifiX } from '@phosphor-icons/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useNetworkState } from '~/hooks/useNetworkState.hook';

import Button from '../../Atoms/Button/Button';
import Typography from '../../Atoms/Typography/Typography';

const OfflineOverlay: FC = () => {
  const { t } = useTranslation();

  const networkState = useNetworkState();

  if (networkState === 'online') return null;

  return (
    <div className="fixed inset-0 z-[1000] flex h-full flex-col items-center justify-center bg-neutral-100">
      <div className="flex max-w-[500px] flex-col items-center text-center">
        <WifiX className="text-black" height={180} width={180} />
        <div className="pt-6">
          <Typography as="h1" className="pb-3 leading-10" size="4xl" weight="bold">
            {t('offline-page.title')}
          </Typography>
          <Typography as="h2" className="text-neutral-500" size="2xl" weight="normal">
            {t('offline-page.description')}
          </Typography>
        </div>
        <div className="pt-6">
          <Button
            as="button"
            size="large"
            variant="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('offline-page.refresh-btn')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OfflineOverlay;
