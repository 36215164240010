import Color from 'color';

const addLeadingHash = (value: string) => (value.includes('#') ? value : `#${value}`);

/**
 * Converts a hexadecimal color to a lighter version based on percentage
 * @param color hexadecimal value of a color
 * @param percentage number between 0 and 1
 * @returns rgb representation of lighter color
 */
export const lightenColor = (color: string | null, percentage = 0.1): string => {
  if (!color || color === 'FFFFFF') return '';
  if (!percentage) return '';

  return Color<string>(addLeadingHash(color)).lighten(percentage).rgb().string();
};

/**
 * Converts a hexadecimal color to a darker version based on percentage
 * @param color hexadecimal value of a color
 * @param percentage number between 0 and 1
 * @returns rgb representation of darker color
 */
export const darkenColor = (color: string | null, percentage = 0.1): string => {
  if (!color || color === 'FFFFFF') return '';
  if (!percentage) return '';

  return Color<string>(addLeadingHash(color)).darken(percentage).rgb().string();
};

/**
 * Determines contrast color based on color, perhaps will need some finetuning, based on this article https://24ways.org/2010/calculating-color-contrast
 * @param color hexadecimal value of a color, i.e. primary color
 * @returns 'black' or 'white', for use as a css color, but could also be '#333333' or 'rgba(255,0,0,1);
 */
export const contrastColor = (color: string | null): string => {
  if (!color) return '';

  const blackColor = 'black';
  const whiteColor = 'white';

  const colorArrayLength = 3;
  const redTreshold = 299;
  const greenTreshold = 587;
  const blueTreshold = 114;
  const colorTreshold = 128;
  const divider = 1000;

  const hexValues = color.match(/.{2}/gi);

  if (hexValues && hexValues.length === colorArrayLength) {
    const redHex = parseInt(hexValues[0], 16);
    const greenHex = parseInt(hexValues[1], 16);
    const blueHex = parseInt(hexValues[2], 16);
    const yiq = (redHex * redTreshold + greenHex * greenTreshold + blueHex * blueTreshold) / divider;

    return yiq >= colorTreshold ? blackColor : whiteColor;
  }

  return blackColor;
};
