/* eslint-disable react-refresh/only-export-components */
import classNames from 'classnames';
import { FC } from 'react';

import CiModeIcon from '~/assets/cimode.svg';
import DutchIcon from '~/assets/dutch.svg';
import EnglishIcon from '~/assets/english.svg';
import FrenchIcon from '~/assets/french.svg';
import GermanIcon from '~/assets/german.svg';
import ItalianIcon from '~/assets/italian.svg';
import SpanishIcon from '~/assets/spanish.svg';
import { SupportedLanguage } from '~/i18n';

export const languageIconSizes = ['small', 'smallmedium', 'medium', 'large'] as const;
export type LanguageIconSizeType = (typeof languageIconSizes)[number];

export interface LanguageIconProps {
  size: LanguageIconSizeType;
  language: string;
  label?: string;
  selected?: boolean;
  onClick?: () => void;
  hasDarkBackground?: boolean;
}

const getSizeClasses = (size: LanguageIconProps['size']) => {
  switch (size) {
    case 'small': {
      return 'w-6 h-6 border';
    }
    case 'smallmedium': {
      return 'w-8 h-8 border';
    }
    case 'large': {
      return 'w-20 h-20 border-2';
    }
    case 'medium':
    default: {
      return 'w-16 h-16 border-2';
    }
  }
};

const LanguageIcon: FC<LanguageIconProps> = ({ size, language, label = '', selected, onClick, hasDarkBackground }) => {
  const getWrapperClasses = () => {
    const textColor = hasDarkBackground ? 'text-white' : 'text-black';
    const opacity = selected === false ? 'opacity-70' : '';

    return `text-xl ${textColor} ${opacity}`;
  };

  const getIconClasses = () => {
    const baseClasses = 'border-neutral-300 bg-neutral-300';
    let iconClasses = '';
    if (hasDarkBackground) {
      iconClasses = selected ? 'md:border-white md:bg-white' : 'md:border-black md:bg-black';
    } else {
      iconClasses = selected ? 'md:border-black md:bg-black' : 'md:border-white md:bg-white';
    }

    return classNames(baseClasses, iconClasses);
  };

  const languageIconMap: Record<SupportedLanguage, string> = {
    'de-DE': GermanIcon,
    'nl-NL': DutchIcon,
    'en-US': EnglishIcon,
    'fr-FR': FrenchIcon,
    'es-ES': SpanishIcon,
    'it-IT': ItalianIcon,
    cimode: CiModeIcon,
  };

  const iconClasses = getIconClasses();
  const wrapperClasses = getWrapperClasses();
  const sizeClasses = getSizeClasses(size);

  return (
    <button className={`inline-flex items-center gap-2 ${wrapperClasses}`} onClick={onClick}>
      <span className={`flex items-center justify-center overflow-hidden rounded-full ${iconClasses} ${sizeClasses}`}>
        <img
          alt={language}
          className={`w-full ${language === 'cimode' ? 'bg-white p-2 ' : ''}`}
          src={languageIconMap[language as SupportedLanguage]}
        />
      </span>
      <span>{label}</span>
    </button>
  );
};

export default LanguageIcon;
