import routes from './routes';

/**
 *  routes that will not trigger an inactivity modal, if the device is not in kiosk mode the inactivity dialog will never be shown
 */

export const routesExcludedFromInactivityChecking = [
  routes.kioskScreensaver,
  routes.checkout,
  routes.kioskServiceGeneral,
  routes.kioskServiceUnlock,
  routes.kioskPrintHistory,
  routes.kioskSelect,
  routes.blocked,
  routes.clientError,
  routes.error,
  // A route that is 'NotFound' is also excluded, but handled as extra condition in the shouldDisableInactivityTimer constant.
];
