/* eslint-disable react-refresh/only-export-components */
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export const fontSizes = [
  'xs',
  'sm',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
  '8xl',
  '9xl',
] as const;

export const fontWeights = [
  'thin',
  'extralight',
  'light',
  'normal',
  'medium',
  'semibold',
  'bold',
  'extrabold',
  'black',
] as const;

export type FontSizeType = (typeof fontSizes)[number];
export type FontWeightType = (typeof fontWeights)[number];

export interface TypographyProps {
  as: 'div' | 'h1' | 'h2' | 'strong' | 'span' | 'p';
  children: ReactNode;
  size: FontSizeType;
  weight?: FontWeightType;
  className?: string;
  ellipsis?: boolean;
}

// we need to write the full classnames because storybook only imports the tailwind classes which it can find, so no dynamic classnames ;-(
const fontSizeClasses: { [key in FontSizeType]: `text-${key}` | `text-${FontSizeType} md:text-${key}` } = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-sm md:text-base',
  lg: 'text-base md:text-lg',
  xl: 'text-base md:text-xl',
  '2xl': 'text-lg md:text-2xl',
  '3xl': 'text-lg md:text-3xl',
  '4xl': 'text-lg md:text-4xl',
  '5xl': 'text-xl md:text-5xl',
  '6xl': 'text-2xl md:text-6xl',
  '7xl': 'text-2xl md:text-7xl',
  '8xl': 'text-2xl md:text-8xl',
  '9xl': 'text-2xl md:text-9xl',
};

const fontWeightClassName: { [key in FontWeightType]: `font-${key}` } = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
};

const Typography: FC<TypographyProps> = ({
  as: Component = 'div',
  size,
  weight = 'normal',
  children,
  className,
  ellipsis,
}) => {
  const sizeClassName = fontSizeClasses[size];
  const weightClassName = fontWeightClassName[weight];
  const ellipsisClassName = ellipsis ? 'text-ellipsis overflow-hidden' : '';
  return (
    <Component className={classNames(sizeClassName, weightClassName, ellipsisClassName, className)}>
      {children}
    </Component>
  );
};

export default Typography;
