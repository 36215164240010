/* eslint-disable react-refresh/only-export-components */
import { Check, Info, Warning, WarningCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FC } from 'react';

import Typography from '../Typography/Typography';

export const alertTypes = ['info', 'danger', 'success', 'warning'] as const;

type AlertTypes = (typeof alertTypes)[number];

export interface AlertProps {
  type?: AlertTypes;
  title?: string;
  message?: string;
}

type StyleObject = {
  bgColor: string;
  textColor: string;
  icon: JSX.Element;
};

const iconSize = 24;

const styleMap: Record<AlertTypes, StyleObject> = {
  warning: {
    bgColor: 'bg-orange-500',
    textColor: 'text-white',
    icon: <Warning color="white" size={iconSize} weight="fill" />,
  },
  danger: {
    bgColor: 'bg-rose-600',
    textColor: 'text-white',
    icon: <WarningCircle color="white" size={iconSize} weight="fill" />,
  },
  success: {
    bgColor: 'bg-green-50',
    textColor: 'text-green-700',
    icon: <Check color="green" size={iconSize} />,
  },
  info: {
    bgColor: 'bg-violet-900',
    textColor: 'text-white',
    icon: <Info color="white" size={iconSize} weight="fill" />,
  },
};

const Alert: FC<AlertProps> = ({ type = 'info', ...props }) => {
  const style = styleMap[type];

  return (
    <div
      className={classNames(
        'inline-flex w-full items-start justify-start gap-3 rounded px-2.5 py-3 text-white',
        style.bgColor,
      )}
    >
      <div className="inline-flex shrink grow basis-0 flex-col items-center justify-center gap-0.5">
        <div className="inline-flex items-center gap-2 leading-normal">
          <div className="relative">{style.icon}</div>
          <Typography as="div" className={style.textColor} size="base" weight="bold">
            {props.title}
          </Typography>
        </div>
        <div className="self-stretch text-center leading-tight">
          <Typography as="div" className={style.textColor} size="base" weight="normal">
            {props.message}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Alert;
