import * as Sentry from '@sentry/react';
import { FC, lazy, Suspense, useLayoutEffect, useMemo, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, ScrollRestoration, useNavigate, useParams } from 'react-router-dom';

import { apiClient } from '~/api-client';
import Loader from '~/components/UI/Atoms/Loader/Loader';
import OfflineOverlay from '~/components/UI/Molecules/OfflineOverlay/OfflineOverlay';
import SignalR from '~/components/UI/SignalR/SignalR';
import { env, secApplicationId } from '~/env';
import { useAppContext } from '~/hooks/useAppContext';
import { useAppRouting } from '~/hooks/useAppRouting.hook';
import { InventoryContextProvider } from '~/hooks/useInventory/InventoryProvider';
import { PrinterProvider } from '~/hooks/usePrinter/PrinterProvider';
import { SignalRContext } from '~/hooks/useSignalR';
import ErrorPage from '~/pages/ErrorPage';
import routes from '~/routes';
import { routesExcludedFromInactivityChecking } from '~/routesExcludedFromInactivity';
import { contrastColor, darkenColor, lightenColor } from '~/utils/color.util';
import { ApplicationError } from '~/utils/errorHandling.util';
import { RouteParams } from '~/utils/routes.util';

const NoActivityTimer = lazy(() => import('~/components/UI/Molecules/NoActivityTimer/NoActivityTimer'));

/**
 * AppLayout component, layout for the app, containing the errorboundary, offlineoverlay, signalr event handler, no activity timer/dialog
 */
const AppLayout: FC = () => {
  const { clientSlug: clientSlugFromRoute, mode: modeFromRoute } = useParams<RouteParams<'start'>>();
  const { appContext, setFromStartup } = useAppContext();
  const navigate = useNavigate();
  const { navigateToPath } = useAppRouting();

  const isFetching = useRef(false);

  const shouldRenderInactivityTimer =
    appContext.device.isKiosk && (import.meta.env.PROD || env.VITE_ENABLE_ACTIVITYTIMER);

  const { primaryColor } = appContext.client.styles;
  const brandLightOpacity = 0.7;

  /**
   * Returns whether `runtimeMode` (in-memory state) matches `modeFromRoute` (url state).
   * This is needed to overcome discrepancy when switching between 'k', 'm' and 's', as can happen often during dev and test.
   * In production situations, mismatch should be rare.
   * TODO (TSO-575): once "BehaviorMode" implementation is created, evaluate whether this method can be moved/deleted/simplified.
   */
  const isRuntimeModeMatchingRouteMode = useMemo(() => {
    switch (modeFromRoute) {
      case undefined: // in early startup phase
        return true;
      case 'k':
        return true; // 'k' is valid for any of the runtimeModes.
      case 'm':
        return appContext.customer.runtimeMode === 'regular';
      case 's':
        return (
          appContext.customer.runtimeMode === 'scanAndGoInAppPayment' ||
          appContext.customer.runtimeMode === 'scanAndGoRelayPayment'
        );
      default:
        throw new Error(`unexpected route mode '${modeFromRoute}'`);
    }
  }, [appContext.customer.runtimeMode, modeFromRoute]);

  /**
   * Startupdata needs to be refetched when route-clientSlug differs from appContext-clientSlug AND appContext lacks api credentials.
   */
  const shouldRefetchStartupData = useMemo(() => {
    // in early startup stages we're still on a clientSlug-less url, so decision about refetching is premature.
    if (clientSlugFromRoute === undefined) return false;

    // If the clientSlug in the appContext still matches the one in the url, startupData still upholds.
    if (clientSlugFromRoute === appContext.client.clientSlug) return false;

    // if runtimeMode is lagging behind routeMode, we need a refetch to correct the runtimeMode.
    if (!isRuntimeModeMatchingRouteMode) return true;

    // So we have clientslug mismatch. If in addition to that, appcontext doesn't have credentials yet/anymore, we need a refetch
    const hasCredentials = appContext.client.clientGuid || appContext.device.deviceId;
    return !hasCredentials;
  }, [
    appContext.client.clientGuid,
    appContext.client.clientSlug,
    appContext.device.deviceId,
    clientSlugFromRoute,
    isRuntimeModeMatchingRouteMode,
  ]);

  const logError = (error: ApplicationError | Error) => {
    if ('type' in error) {
      Sentry.captureException(error?.error?.message ?? error.type, { extra: { data: JSON.stringify(error) } });
    } else {
      Sentry.captureException(error);
    }
  };

  const signalrAccessToken = useMemo(() => {
    // pushmessaging applies to kiosks only and setting up the channel is postponed until virtualDevice is known
    if (!appContext.device.isKiosk || !appContext.device.virtualDeviceId) {
      return false;
    }

    return `DEVICE:${appContext.client.clientId}:${appContext.device.deviceId}`;
  }, [appContext.client, appContext.device.deviceId, appContext.device.isKiosk, appContext.device.virtualDeviceId]);

  // Refetch only for mobile/non-kiosk mode
  useLayoutEffect(() => {
    // TODO: We have startup-logic in multiple places (search for term 'setFromStartup' to get an idea).
    //       This troubles the understanding of the bootstrapping phase of our app.
    if (!shouldRefetchStartupData || isFetching.current) return;

    const getStartup = async () => {
      isFetching.current = true;
      const data = await apiClient.getStartup({
        headers: { secApplicationId, slug: clientSlugFromRoute },
      });
      isFetching.current = false;

      if (data?.error) return navigate(routes.error, { state: { error: data.error } });
      setFromStartup(data);
    };

    // kiosk validation if context gets lost
    // if `mode` === 'kiosk', navigate to kiosk sign in,
    if (modeFromRoute === 'k') {
      return navigateToPath(routes.kioskSignIn, true);
    }
    // - else (mode === 'nonKiosk') => refetch
    void getStartup();
  }, [
    appContext.customer.runtimeMode,
    clientSlugFromRoute,
    modeFromRoute,
    navigate,
    navigateToPath,
    setFromStartup,
    shouldRefetchStartupData,
  ]);

  return (
    <>
      {primaryColor && (
        <style
          dangerouslySetInnerHTML={{
            __html: ` :root {
          --bg-brand: #${primaryColor};
          --bg-brand-700: ${darkenColor(primaryColor)};
          --bg-brand-500: ${lightenColor(primaryColor)};
          --bg-brand-light: ${lightenColor(primaryColor, brandLightOpacity)};
          --text-color: ${contrastColor(primaryColor)};
        }`,
          }}
        />
      )}
      <SignalRContext.Provider
        automaticReconnect
        connectEnabled={!!signalrAccessToken}
        url={`${env.VITE_SIGNALR_URL}?access_token=${signalrAccessToken}`}
        withCredentials={false}
      >
        <ScrollRestoration />
        <ErrorBoundary FallbackComponent={ErrorPage} onError={logError}>
          <OfflineOverlay />
          <PrinterProvider>
            <InventoryContextProvider>
              {/* Don't render the children yet during retry of the Startup call, as these children assume Startup data is available. */}
              {shouldRefetchStartupData ? <Loader /> : <Outlet />}

              <Suspense>
                {shouldRenderInactivityTimer && (
                  <NoActivityTimer excludedRoutes={routesExcludedFromInactivityChecking} />
                )}
              </Suspense>

              <SignalR />
            </InventoryContextProvider>
          </PrinterProvider>
        </ErrorBoundary>
      </SignalRContext.Provider>
    </>
  );
};

export default AppLayout;
