import { QueryObserverBaseResult } from '@tanstack/react-query';
import { createContext } from 'react';

import { Api } from '~/api-client';

import { emptyInventory } from './emptyInventory.constant';
import { Inventory } from './Inventory.typings';

type RequestState = Omit<QueryObserverBaseResult<Api.InventoryResult>, 'data'>;
type UpdateProductStockCount = (productId: number, remainingStockCount: number) => void;
type UseInventoryData = Inventory & Partial<RequestState> & { updateProductStockCount: UpdateProductStockCount };

const createEmptyFn = (fnName: string) => () => {
  // eslint-disable-next-line no-console
  console.warn(`AppContext.${fnName} called before initialized`);
};

export const InventoryContextContext = createContext<UseInventoryData>({
  ...emptyInventory,
  updateProductStockCount: createEmptyFn('updateProductStockCount'),
});

InventoryContextContext.displayName = 'InventoryContext';
