/** Load an image into browser memory */
export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.crossOrigin = 'anonymous';
    image.onload = () => resolve(image);
    image.onerror = (err) => reject(err);
  });
};

/**
 * Download an image and serialize it in the requested size to a base64string.
 * Returns null if anything fails.
 */
export const serializeImage = async (url: string, imageHeight: number = 100): Promise<string | null> => {
  try {
    const image = await loadImage(url);

    // imageWidth follows from imageHeight and aspect ratio
    const imageWidth = Math.floor(imageHeight * (image.width / image.height));

    // create canvas and draw the image onto it, in the arrived at size
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d')!;
    canvas.width = imageWidth;
    canvas.height = imageHeight;
    canvasContext.drawImage(image, 0, 0, imageWidth, imageHeight);

    return canvas.toDataURL('image/png').replace('data:image/png;base64,', '');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Serializing image "${url}" to base64 string failed`, e);
    return null;
  }
};
