import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '~/hooks/useAppContext';
import { useAppRouting } from '~/hooks/useAppRouting.hook';
import { useCacheManager } from '~/hooks/useCacheManager.hook';
import { useCart } from '~/hooks/useCart';
import { useInventory } from '~/hooks/useInventory';
import { SignalRContext } from '~/hooks/useSignalR';
import { SignalREventType } from '~/hooks/useSignalR/SignalR.typings';
import routes from '~/routes';

const SignalR: FC = () => {
  const {
    setAppContext,
    appContext: {
      client: { clientSlug },
    },
  } = useAppContext();
  const { navigateToPath, isAppOnRoute } = useAppRouting();
  const queryClient = useQueryClient();
  const { clearCart } = useCart();

  const navigate = useNavigate();
  const { refetch: refetchInventory, updateProductStockCount } = useInventory();

  const cacheManager = useCacheManager();

  /** Listen for updates to product stock
   * @param eventData: an array of products having updated remaingStock
   */
  SignalRContext.useSignalREffect(
    'ReceiveProductStockUpdate',
    (eventData: { productId: number; remainingStock: number }[]) => {
      for (const { productId, remainingStock } of eventData) {
        updateProductStockCount(productId, remainingStock);
      }
    },
    [],
  );

  SignalRContext.useSignalREffect(
    'ReceiveMessage',
    (_, event: SignalREventType) => {
      switch (event) {
        case 'lock':
          navigateToPath(routes.blocked, { clientSlug });
          break;

        case 'restart':
          void (async () => {
            await cacheManager.clearAllCaches();
            navigate(routes.exit);
          })();
          break;

        case 'refresh':
          void (async () => {
            await cacheManager.clearAllCaches();
            return refetchInventory?.();
          })();
          break;

        case 'unlock':
          // abort unlocking if already unlocked, to avoid unintended resetting
          if (!isAppOnRoute(routes.blocked)) break;

          clearCart();
          queryClient.getQueryCache().clear();
          navigateToPath(routes.kioskExit, { clientSlug });
          break;

        case 'activateNowOrLater':
        case 'disableNowOrLater':
          setAppContext((curr) => ({
            ...curr,
            client: {
              ...curr.client,
              refetch: true,
            },
          }));
          break;

        default:
          Sentry.captureException('Unknown signal-r event', { extra: { data: event } });
      }
    },
    [],
  );

  return null;
};

export default SignalR;
