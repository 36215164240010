/* eslint-disable react-refresh/only-export-components */
import { ShoppingBag } from '@phosphor-icons/react';
import { FC } from 'react';

import Badge from '../Badge/Badge';

export const basketSizes = ['small', 'large'] as const;
type BasketSizeType = (typeof basketSizes)[number];

export interface BasketProps {
  size: BasketSizeType;
  quantity: number;
  label: string;
}

const Basket: FC<BasketProps> = ({ size, quantity = 0, label }) => {
  const isLarge = size === 'large';

  const isFilled = quantity > 0;
  const textClasses = isLarge ? 'text-2xl font-bold leading-loose' : 'text-base font-bold leading-normal';

  return (
    <span className="flex flex-row items-center gap-2 text-white">
      <span className="relative block">
        <ShoppingBag size={isLarge ? '56' : '32'} weight={isFilled ? 'fill' : undefined} />
        {isFilled && (
          <Badge
            className="absolute"
            color="primary"
            size={size}
            style={{ top: '-10px', right: '-12px' }}
          >{`${quantity}`}</Badge>
        )}
      </span>
      <span className={textClasses}>{label}</span>
    </span>
  );
};

export default Basket;
