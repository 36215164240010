import { z } from 'zod';

import { schemas } from '~/api-client/generated/swagger';

import { productTypes } from '../useInventory';

export const cartProductLineBaseSchema = z.object({
  id: z.number().int().min(0),
  type: productTypes,
  name: z.string(),
  count: z.number().int().min(-1), // Negative for "removed" items like ingredients
  price: z.number(),
  extraPrice: z.number().nullable(),
  discountedPrice: z.number().nullable(),
  takenFromProductCollectionId: z.number().int().min(0).nullable(),
  takenFromProductChoiceId: z.number().int().min(0).nullable(),
  takenFromIngredientId: z.number().int().min(0).nullable(),
  deposit: z
    .object({
      id: z.number().int().min(0),
      price: z.number(),
    })
    .nullable(),
});

export const cartProductLineDetailsSchema = z.object({
  supplements: z.array(z.string()),
  ingredients: z.array(z.string()),
  preparationChoices: z.array(z.string()),
});

export const preparationChoiceSchema = z.object({
  takenFromPreparationChoiceId: z.number().int().min(0),
  id: z.number().int().min(0),
  name: z.string(),
  optionName: z.string(),
});

export const cartProductLineSchema = cartProductLineBaseSchema.extend({
  minimumAge: z.number().int().min(0),
  depositPrice: z.number(),
  supplements: z.array(cartProductLineBaseSchema),
  preparationChoices: z.array(preparationChoiceSchema),
});

export const calculatedSchema = z.object({
  totalCount: z.number().int().min(0),
  subTotalPrice: z.number(),
  /** Deposit price. */
  depositPrice: z.number(),
  /** Total price. Sum of `subTotalPrice`, `depositPrice` and `vatPrice`. */
  totalPrice: z.number(),
  /** Oldest minimum age. */
  highestMinimumAge: z.number().int().min(0),
});

export const cartMenuSchema = z.object({
  menuGuid: z.string().uuid(),
  productId: z.number().int().min(0),
  price: z.number(),
  extraPrice: z.number().nullable(),
  name: z.string(),
});

export const cartProductGroupingSchema = z
  .object({
    /** Internal (front-end only) identifier for the line-item / grouping. */
    lineId: z.string().uuid(),
    isUpgradableToMenu: z.boolean(),
    hasBeenUpgradedToMenu: z.boolean(),
    /** Has the grouping already been added to the cart (before). */
    hasBeenAddedToCart: z.boolean(),
    count: z.number().int().min(0),
    menu: cartMenuSchema.nullable(),
    products: z.array(cartProductLineSchema),
  })
  .merge(calculatedSchema);

export const orderSchema = schemas.Order.omit({
  productGrouping: true,
  orderType: true,
  userName: true,
  tableNumber: true,
  timingOption: true, // Already in key of the record
})
  .extend({
    productGrouping: z.array(cartProductGroupingSchema),
    outOfStock: z.array(cartProductGroupingSchema),
  })
  .merge(calculatedSchema);

export const cartSchema = z
  .object({
    currentActiveOrder: schemas.TimingOption,
    totalCount: z.number().int().min(0),
    orders: z.record(schemas.TimingOption, orderSchema),
    stashTimestamp: z.number().int().optional(),
  })
  .merge(calculatedSchema);

export type CartProductLine = z.output<typeof cartProductLineSchema>;
export type CartProductLineDetails = z.output<typeof cartProductLineDetailsSchema>;
export type PreparationChoice = z.output<typeof preparationChoiceSchema>;
export type CartProductGroupingInput = z.input<typeof cartProductGroupingSchema>;
export type CartProductGrouping = z.output<typeof cartProductGroupingSchema>;
export type Order = z.output<typeof orderSchema>;
export type Calculated = z.output<typeof calculatedSchema>;
export type Cart = z.output<typeof cartSchema>;
export type CartProductLineBase = z.output<typeof cartProductLineBaseSchema>;
export type CartMenu = z.output<typeof cartMenuSchema>;
