import { CircleNotch } from '@phosphor-icons/react';
import { FC } from 'react';

interface LoaderIconProps {
  color?: 'primary' | 'white';
  size?: number;
}

const LoaderIcon: FC<LoaderIconProps> = ({ color = 'primary', size = 32 }) => {
  return (
    <CircleNotch className={color === 'primary' ? 'fill-primary' : 'fill-white'} size={size}>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        dur="0.8s"
        from="0 0 0"
        repeatCount="indefinite"
        to="360 0 0"
        type="rotate"
      />
    </CircleNotch>
  );
};

export default LoaderIcon;
