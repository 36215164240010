/* eslint-disable @typescript-eslint/no-magic-numbers */
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { env } from './env';
import { getForSentry, PersistenceSpecification } from './hooks/useBrowserPersistence.hook';

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: true,
      maskAllText: false,
    }),
  ],
  tracePropagationTargets: [
    'localhost', // dev
    /^https:\/\/.+\.twelve\.dev/, // staging
    /^https:\/\/api\.so\.twelve\.eu/, // prod
    /^https:\/\/languagestsyncprd\.blob\.core\.windows\.net/, // i18n
  ],
  environment: env.VITE_APP_ENV,
  release: env.VITE_APP_VERSION,
  sampleRate: env.VITE_APP_ENV === 'production' ? 0.05 : 1.0,
  tracesSampleRate: env.VITE_APP_ENV === 'production' ? 0.01 : 1.0,
  replaysSessionSampleRate: 1, // Don't replay successful sessions
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    hint.attachments = [
      {
        filename: 'appContext.json',
        data: getForSentry(PersistenceSpecification.AppContext) || '{}',
        contentType: 'application/json',
      },
      {
        filename: 'cart.json',
        data: getForSentry(PersistenceSpecification.Cart) || '{}',
        contentType: 'application/json',
      },
    ];
    return event;
  },
});

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
) as typeof createBrowserRouter;
